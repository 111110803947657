import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import classNames from 'classnames';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import humanize from 'string-humanize';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { appRoutes } from '@/lib/legacy-routes';
import { formattedAsTruncatedHourDecimal } from '../utils';

function HasDeletedHours() {
  return (
    <Fragment>
      <div className="boss-table__cell-border boss-table__cell-border_position_bottom boss-table__cell-border_color_red-saturated" />
      <div className="boss-corner boss-corner_position_bottom-left boss-corner_color_accent-red-saturated">
        <span className="boss-corner__icon boss-corner__icon_trash" />
      </div>
    </Fragment>
  );
}

export function PayrollReportItem(props) {
  const [onShowReportModal, mWeekDates, securityFinanceReport, onMarkComplete] = oFetch(
    props,
    'onShowReportModal',
    'mWeekDates',
    'securityFinanceReport',
    'onMarkComplete',
  );
  const [
    financeReportId,
    staffMemberId,
    staffMemberDisabled,
    staffMemberName,
    pendingCalculation,
    totalCents,
    status,
    hoursPending,
    completionDateReached,
    completedAt,
  ] = oFetch(
    securityFinanceReport,
    'id',
    'staffMemberId',
    'staffMemberDisabled',
    'staffMemberName',
    'pendingCalculation',
    'totalCents',
    'status',
    'hoursPending',
    'completionDateReached',
    'completedAt',
  );

  const reportCompletable = oFetch(securityFinanceReport, "completable")

  const completedAtTooltipContent = completedAt && (
    <p>
      Completed at: {safeMoment.iso8601Parse(completedAt).format(utils.humanDateFormatWithTime())}
    </p>
  );
  const staffMemberNameCellClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_user-disabled': staffMemberDisabled,
  });

  const rowClassName = classNames({
    'boss-table__row': true,
    'boss-table__row_state_pre-calculated': pendingCalculation,
  });

  const staffMemberNameCellTextClassName = 'boss-table__text';

  const formattedTotal = utils.moneyFormat(totalCents / 100);

  function getEffectiveStatus() {
    if (status === 'ready' && !completionDateReached) {
      return 'pending';
    } else if (status === 'ready' && hoursPending) {
      return 'incomplete';
    } else {
      return status;
    }
  }
  const effectiveStatus = getEffectiveStatus();
  const isRequiringUpdate = effectiveStatus === 'requiring_update';

  const statusClassName = classNames({
    'boss-table__text': true,
    'boss-table__text_role_pending-status': effectiveStatus === 'ready' || isRequiringUpdate,
    'boss-table__text_role_alert-status': !reportCompletable,
    'boss-table__text_role_success-status': status === 'done',
  });

  function getTotalHours() {
    const totalMinutes = mWeekDates.reduce((acc, mWeekDay) => {
      const weekDayName = moment.weekdays(mWeekDay.isoWeekday()).toLowerCase();
      const standardMinutes = oFetch(securityFinanceReport, `${weekDayName}StandardHoursMinutes`);
      const specialMinutes = oFetch(securityFinanceReport, `${weekDayName}SpecialHoursMinutes`);
      const total = standardMinutes + specialMinutes;
      return acc + total;
    }, 0);
    return formattedAsTruncatedHourDecimal(totalMinutes);
  }

  function renderCompletedAtTooltip(content) {
    return (
      <Tooltip arrow theme="light" position="bottom" interactive html={content}>
        <p className="boss-table__text boss-table__text_role_action boss-table__text_role_details boss-table__text_role_success-status">
          Done
        </p>
      </Tooltip>
    );
  }

  function renderDayIssuesTooltip(dayNeedingCompletion, dDate) {
    const tooltipContent = (
      <span>
        <a
          target="_blank"
          rel="noreferrer"
          href={appRoutes.staffMemberHoursOverview({ staffMemberId, dDate })}
        >
          {dayNeedingCompletion.join(', ')}
        </a>
      </span>
    );

    return (
      <Tooltip arrow theme="light" position="right" interactive html={tooltipContent}>
        <span className="boss-table__tooltip">
          <span className="boss-tooltip boss-tooltip_role_alert">
            <span className="boss-tooltip__icon" />
          </span>
        </span>
      </Tooltip>
    );
  }

  function renderWeekDaysCells(params) {
    return mWeekDates.map((mDate, index) => {
      const weekDayName = moment.weekdays(mDate.isoWeekday()).toLowerCase();
      const standardMinutes = oFetch(securityFinanceReport, `${weekDayName}StandardHoursMinutes`);
      const specialMinutes = oFetch(securityFinanceReport, `${weekDayName}SpecialHoursMinutes`);
      const hasDeletedHours = oFetch(securityFinanceReport, `${weekDayName}HasDeletedHours`);
      const daysNeedingCompletion = oFetch(securityFinanceReport, 'daysNeedingCompletion');
      const dDate = mDate.toDate();
      const uiFormattedDate = mDate.format(utils.uiRotaDateFormat);
      const dayNeedingCompletion = daysNeedingCompletion[uiFormattedDate];
      const dayHasIssues = hoursPending && !!dayNeedingCompletion;
      const cellClass = classNames('boss-table__cell', {
        'boss-table__cell-area_state_alert': dayHasIssues,
      });

      if (standardMinutes === 0 && specialMinutes === 0) {
        return (
          <div key={index} className={cellClass}>
            <p className="boss-table__text">
              0{dayHasIssues && renderDayIssuesTooltip(dayNeedingCompletion, dDate)}
            </p>

            {hasDeletedHours && <HasDeletedHours />}
          </div>
        );
      }

      return (
        <div key={index} className={cellClass}>
          <div className="boss-table__cell-group">
            <div className="boss-table__cell-area boss-table__cell-area_role_normal-hours">
              {standardMinutes === 0 && (
                <p className="boss-table__text">
                  0{dayHasIssues && renderDayIssuesTooltip(dayNeedingCompletion, dDate)}
                </p>
              )}
              {standardMinutes !== 0 && (
                <p className="boss-table__text">
                  <a
                    href={appRoutes.staffMemberHoursOverview({ staffMemberId, dDate })}
                    className="boss-table__link"
                  >
                    {formattedAsTruncatedHourDecimal(standardMinutes)}
                  </a>
                  {dayHasIssues && renderDayIssuesTooltip(dayNeedingCompletion, dDate)}
                </p>
              )}
            </div>
            <div className="boss-table__cell-area boss-table__cell-area_role_head-doorman-hours">
              {specialMinutes === 0 && <p className="boss-table__text">0</p>}
              {specialMinutes !== 0 && (
                <p className="boss-table__text">
                  <a
                    href={appRoutes.staffMemberHoursOverview({ staffMemberId, dDate })}
                    className="boss-table__link"
                  >
                    {formattedAsTruncatedHourDecimal(specialMinutes)}
                  </a>
                </p>
              )}
            </div>
          </div>
          {hasDeletedHours && <HasDeletedHours />}
        </div>
      );
    });
  }

  return (
    <div className={rowClassName}>
      <div className={staffMemberNameCellClassName}>
        <p className={staffMemberNameCellTextClassName}>
          <a
            href={appRoutes.staffMember(staffMemberId)}
            className="boss-table__link"
            style={{ textTransform: 'capitalize' }}
          >
            {staffMemberName}
          </a>
        </p>
      </div>
      {renderWeekDaysCells({ mWeekDates, staffMemberId })}
      <div className="boss-table__cell">
        <a
          onClick={() => onShowReportModal({ staffMemberName, financeReportId, status })}
          className="boss-table__text boss-table__text_role_important"
        >
          {getTotalHours()}
        </a>
      </div>
      <div className="boss-table__cell">
        <a
          onClick={() => onShowReportModal({ staffMemberName, financeReportId, status })}
          className="boss-table__text boss-table__text_role_important"
        >
          {formattedTotal}
        </a>
      </div>
      <div className="boss-table__cell">
        {completedAt && renderCompletedAtTooltip(completedAtTooltipContent)}
        {!completedAt && <p className={statusClassName}>{humanize(effectiveStatus)}</p>}
        { reportCompletable && (
          <div className="boss-table__actions">
            <button
              onClick={() => onMarkComplete({ staffMemberId })}
              className="boss-button boss-button_type_extra-small boss-button_role_confirm-light boss-table__action"
            >
              Mark Completed
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
