// Only supports adds single zero for numbers less than 10
export function numberToStringWithLeadingZero(number: number): string {
  if (number < 0) {
    throw new Error(`numberToStringWithLeadingZero does not support negative numbers. Supplied number: ${number}`);
  }
  return number <= 9 ? `0${number}` : `${number}`;
}

// Converts integer milliseconds to formatted hours and minutes with no padded zeros e.g. 4h23m
export function msToFormattedHoursAndMinutes(durationMs: number): string {
  const absTimeInMs = Math.abs(durationMs);
  const hours = Math.trunc(absTimeInMs / 1000 / 60 / 60);
  const minutes = Math.trunc((absTimeInMs / 1000 / 60) % 60);

  if (hours === 0 && minutes === 0) {
    return "0m";
  }
  return (
    `${hours === 0 ? '' : `${hours}h `}${minutes === 0 ? '' : `${numberToStringWithLeadingZero(minutes)}m`}`
  ).trim();
}

// Expects integer
export function secondsToFormattedHoursAndMinutes(durationSeconds: number): string {
  const absTimeInSeconds = Math.abs(durationSeconds);
  const absTimeInMs = absTimeInSeconds * 1000;

  return msToFormattedHoursAndMinutes(absTimeInMs);
}

// Expects integer
export function minutesToFormattedHoursAndMinutes(durationMinutes: number): string {
  const absTimeInMinutes = Math.abs(durationMinutes);
  const absTimeInMs = absTimeInMinutes * 60 * 1000;

  return msToFormattedHoursAndMinutes(absTimeInMs);
}