import React, { useMemo } from 'react';
import Select from '@/lib/boss-react-select';
import { formatAsCurrency } from '@/lib/currency-helpers';
import {
  mossHourTagsReportIndex as importedMossHourTagsReportIndex,
} from '@/lib/app-routes';
import { MossHourTagsSectionProps, MossHourTag, ALL_TAGS_ID_VALUE, SelectOption, UNTAGGED_FILTER_VALUE, UntaggedMossHourTagSummary } from '../types';
const appRoutes = {
  mossHourTagsReportIndex: importedMossHourTagsReportIndex,
};

export function MossHourTagsSection(props: MossHourTagsSectionProps): JSX.Element {
  const filter = props.filter;

  const [filterTags, setFilterTags] = React.useState<(number | typeof UNTAGGED_FILTER_VALUE)[]>([]);
  const [enabledTags, disabledTags] = useMemo(() => {
    const enabledTags = [] as MossHourTag[];
    const disabledTags = [] as MossHourTag[];
    props.mossHourTags.forEach((mossHourTag) => {
      if (mossHourTag.isDisabled) {
        disabledTags.push(mossHourTag);
      } else {
        enabledTags.push(mossHourTag);
      }
    });
    return [enabledTags, disabledTags];
  }, [props.mossHourTags]);

  const effectiveFilteredTagsLength = enabledTags.length + disabledTags.length + (filter.showUntagged ? 1 : 0);
  const effectiveFilteredOutTagLength = props.filteredOutMossHourTags.length + (filter.showUntagged ? 0 : 1);
  const totalMossHourTagCount = effectiveFilteredTagsLength + effectiveFilteredOutTagLength;
  const showingAll = effectiveFilteredOutTagLength === 0;

  function toggleHighlight(args: { mossHourTagId: number | typeof UNTAGGED_FILTER_VALUE }): () => void {
    return () => {
      if (props.highlightedTagId === args.mossHourTagId) {
        return;
      }

      props.setHighlightedTagId(args.mossHourTagId);
    };
  }

  function handleTagChange(selectedOptions: string): void {
    const arrayValues = selectedOptions
      .split(',')
      .filter(Boolean)
      .map((value) => {
        if (value === UNTAGGED_FILTER_VALUE) {
          return UNTAGGED_FILTER_VALUE;
        }
        return parseInt(value);
      });
    setFilterTags(arrayValues);
  }

  function addTags(): void {
    const filteredIds: number[] = [];
    let filteredShowUntagged: boolean = false;
    filterTags.forEach((filterTagValue) => {
      if (filterTagValue === UNTAGGED_FILTER_VALUE) {
        filteredShowUntagged = true;
      } else {
        filteredIds.push(filterTagValue);
      }
    });

    const mossHourTagIds = [
      ...props.mossHourTags.map((m) => m.id),
      ...filteredIds,
    ];

    window.location.href = appRoutes
      .mossHourTagsReportIndex({
        startDate: props.startDate,
        mossHourTagIds: mossHourTagIds,
        showUntagged: filteredShowUntagged,
      });
  }

  function handleShowAllClick(): void {
    const href = appRoutes.mossHourTagsReportIndex({
      startDate: props.startDate,
      mossHourTagIds: ALL_TAGS_ID_VALUE,
      showUntagged: true,
    });
    window.location.href = href;
  }

  function handleShowOnlyClick(mossHourTagId: number): void {
    window.location.href = appRoutes.mossHourTagsReportIndex({
      startDate: props.startDate,
      mossHourTagIds: [mossHourTagId],
      showUntagged: false,
    });
  }

  function handleShowUntaggedOnlyClick(): void {
    window.location.href = appRoutes.mossHourTagsReportIndex({
      startDate: props.startDate,
      mossHourTagIds: [],
      showUntagged: true,
    });
  }

  function handleRemoveTagClick(mossHourTagId: number): void {
    let mossHourTagIds = [] as number[];
    if (props.mossHourTags.length === 0) {
      // Need all tags except this one
      disabledTags.forEach((mossHourTag) => {
        if (mossHourTag.id !== mossHourTagId) {
          mossHourTagIds.push(mossHourTag.id);
        }
      });
      enabledTags.forEach((mossHourTag) => {
        if (mossHourTag.id !== mossHourTagId) {
          mossHourTagIds.push(mossHourTag.id);
        }
      });
    } else if ((props.mossHourTags.length === 1) && (props.mossHourTags[0]?.id && (props.mossHourTags[0]?.id === mossHourTagId))) {
      // need all tags so do nothing
    } else {
      // need current tags except this one
      mossHourTagIds = props.mossHourTags.filter((mossHourTag) => mossHourTag.id !== mossHourTagId).map((mossHourTag) => mossHourTag.id);
    }

    const effectiveMossHourTagIds = mossHourTagIds.length === 0 && !filter.showUntagged ? ALL_TAGS_ID_VALUE : mossHourTagIds;
    const effectiveShowUntagged = mossHourTagIds.length === 0 && !filter.showUntagged ? true : filter.showUntagged;

    const href = appRoutes.mossHourTagsReportIndex({
      startDate: props.startDate,
      mossHourTagIds: effectiveMossHourTagIds,
      showUntagged: effectiveShowUntagged,
    });
    window.location.href = href;
  }

  function handleRemoveUntaggedTagClick(): void {
    if (props.mossHourTags.length === 0) {
      // none left so show all
      window.location.href = appRoutes.mossHourTagsReportIndex({
        startDate: props.startDate,
        mossHourTagIds: ALL_TAGS_ID_VALUE,
        showUntagged: true,
      });
    } else {
      window.location.href = appRoutes.mossHourTagsReportIndex({
        startDate: props.startDate,
        mossHourTagIds: filter.mossHourTagIds,
        showUntagged: false,
      });
    }
  }


  function renderUntaggedMossHourSummary(untaggedMossHourTagSummary: UntaggedMossHourTagSummary): JSX.Element {
    const totalCents = untaggedMossHourTagSummary.owedHourCents + untaggedMossHourTagSummary.hoursAcceptancePeriodCents;

    return (
      <div
        onClick={toggleHighlight({ mossHourTagId: UNTAGGED_FILTER_VALUE })}
        key={`mossHourTag:${UNTAGGED_FILTER_VALUE}`}
        className={[
          'boss-tag boss-tag_size_xl',
          'boss-tag_role_hours-tag',
          'boss-tag_role_action',
          'js-tippy-tag',
          props.highlightedTagId === UNTAGGED_FILTER_VALUE ? 'boss-tag_state_selected' : null,
        ].filter(Boolean).join(' ')}
        aria-expanded="false"
      >
        {untaggedMossHourTagSummary.isCalculating && <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
          <span className="boss-corner__icon boss-corner__icon_calculator" />
        </div>}
        <p className="boss-tag__text">
          {`${untaggedMossHourTagSummary.name} `}
          <span className="boss-tag__text-bold">{formatAsCurrency({ cents: totalCents })}</span>
        </p>
        <div
          className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_hide boss-tag__service_type_hidden"
          onClick={() => handleShowUntaggedOnlyClick()}
        />
        <div
          className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_close"
          onClick={() => handleRemoveUntaggedTagClick()}
        />
      </div>
    );
  }

  function renderMossHourTags(args: { mossHourTags: MossHourTag[], untaggedMossHourTagSummary: UntaggedMossHourTagSummary | null }): JSX.Element[] {
    const mossHourTags = args.mossHourTags;
    const untaggedMossHourTagSummary = args.untaggedMossHourTagSummary;
    const showUntaggedMossHourTagSummary = untaggedMossHourTagSummary !== null;

    const untaggedMossHourTagHtmlAry: JSX.Element[] = showUntaggedMossHourTagSummary ? ([
      renderUntaggedMossHourSummary(untaggedMossHourTagSummary)
    ]) : [] ;
    return untaggedMossHourTagHtmlAry.concat(
      mossHourTags.map((mossHourTag) => {
        const totalCents = mossHourTag.owedHourCents + mossHourTag.hoursAcceptancePeriodCents;

        return (
          <div
            onClick={toggleHighlight({ mossHourTagId: mossHourTag.id })}
            key={`mossHourTag:${mossHourTag.id}`}
            className={[
              'boss-tag boss-tag_size_xl',
              'boss-tag_role_hours-tag',
              'boss-tag_role_action',
              'js-tippy-tag',
              mossHourTag.isDisabled ? 'boss-tag_state_disabled' : null,
              mossHourTag.id === props.highlightedTagId ? 'boss-tag_state_selected' : null,
            ].filter(Boolean).join(' ')}
            aria-expanded="false"
          >
            {mossHourTag.isCalculating && <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
              <span className="boss-corner__icon boss-corner__icon_calculator" />
            </div>}
            <p className="boss-tag__text">
              {`${mossHourTag.name} `}
              <span className="boss-tag__text-bold">{formatAsCurrency({ cents: totalCents })}</span>
            </p>
            <div
              className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_hide boss-tag__service_type_hidden"
              onClick={() => handleShowOnlyClick(mossHourTag.id)}
            />
            <div
              className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_close"
              onClick={() => handleRemoveTagClick(mossHourTag.id)}
            />
          </div>
        );
      })
    );
  }

  const selectOptions: SelectOption[] = props.filteredOutMossHourTags.map((mossHourTag) => {
    return {
      label: mossHourTag.name,
      value: mossHourTag.id
    };
  });
  if (!props.filter.showUntagged) {
    selectOptions.unshift({
      label: 'Untagged',
      value: UNTAGGED_FILTER_VALUE,
    });
  }

  return (
    <section className="boss-board boss-board_context_stack boss-board_role_hours-tags">
      <div className="boss-board__main">
        <div className="boss-board__main-inner">
          <div className="boss-board__filter">
            <div
              className="boss-form"
            >
              <div className="boss-form__row boss-form__row_align_center">
                <div className="boss-form__field boss-form__field_layout_min">
                  <p className="boss-form__text">
                    <span>Showing {effectiveFilteredTagsLength} of {totalMossHourTagCount}</span>
                    {!showingAll && (
                      <button
                        type="button"
                        className="boss-button boss-button_type_extra-small boss-form__action"
                        onClick={handleShowAllClick}
                      >Show All</button>
                    )}
                  </p>
                </div>

                <div className="boss-form__field boss-form__field_layout_max">
                  <div className="boss-form__select">
                    <Select
                      disabled={props.filteredOutMossHourTags.length === 0 && props.filter.showUntagged}
                      placeholder={"Filtered Out Tags..."}
                      value={filterTags}
                      label={null}
                      simpleValue
                      options={selectOptions}
                      multi
                      clearable={true}
                      onChange={handleTagChange}
                    />
                  </div>
                </div>
                <button
                  disabled={filterTags.length === 0 && props.filteredOutMossHourTags.length === 0}
                  onClick={() => addTags()}
                  className="boss-button"
                >Update</button>
              </div>
            </div>
          </div>

          <div className="boss-board__tags">
            {effectiveFilteredTagsLength === 0 && (
              <div className="boss-board__tags-group">
                <div className="boss-board__tags-group-inner">
                  <div className="boss-board__tags-group-text">No tags found</div>
                </div>
              </div>
            )}

            {effectiveFilteredTagsLength > 0 && (
              <div className="boss-board__tags-group">
                {(
                  renderMossHourTags({
                    mossHourTags: enabledTags,
                    untaggedMossHourTagSummary: filter.showUntagged ? props.untaggedMossHourTagSummary : null,
                  })
                )}
              </div>
            )}

            {disabledTags.length > 0 && (
              <div className="boss-board__tags-group">
                {(
                  renderMossHourTags({
                    mossHourTags: disabledTags,
                    untaggedMossHourTagSummary: null,
                  })
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
