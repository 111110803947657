import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import classNames from 'classnames';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { appRoutes as legacyAppRoutes } from '@/lib/legacy-routes';
import { Tooltip } from 'react-tippy';
import pureToJs from '@/hocs/pure-to-js';
import { RotaWeek } from '@/lib/rota-date';
import format from 'date-fns/format';
import { SHORT_TIME_FORMAT, COMMON_DATE_FORMAT, UI_DATE_FORMAT } from '@/lib/date-fns-formats';
import { minutesToFormattedHoursAndMinutes } from '@/lib/format-funcs';
import { FaMoneyBillTrendUp } from "react-icons/fa6";

import { PaidHolidaysColumn } from './paid-holidays-column';
import { StatusColumn } from './status-column';
const cellStyle = { flexDirection: 'row', alignItems: 'center' };

function renderOwedHoursTooltipContent(args) {
  const owedHours = oFetch(args, 'owedHours');
  if (oFetch(owedHours, 'length') === 0) {
    return null;
  }
  const financeReportWeek = oFetch(args, 'financeReportWeek');
  const financeReportWeekStartDate = financeReportWeek.startDate();

  return (
    <ul>
      <h3>Owed Hours</h3>

      {owedHours.map((owedHour) => {
        const sOwedHourStartsAt = oFetch(owedHour, 'startsAt');
        const dOwedHourStartsAt = sOwedHourStartsAt ? safeMoment.iso8601Parse(sOwedHourStartsAt).toDate() : null;
        const formattedStartsAt = dOwedHourStartsAt ? format(dOwedHourStartsAt, SHORT_TIME_FORMAT) : null;
        const sOwedHourEndsAt = oFetch(owedHour, 'endsAt');
        const dOwedHourEndsAt = sOwedHourEndsAt ? safeMoment.iso8601Parse(sOwedHourEndsAt).toDate() : null;
        const formattedEndsAt = dOwedHourEndsAt ? format(dOwedHourEndsAt, SHORT_TIME_FORMAT) : null;

        const dPaySlipDate = safeMoment.uiDateParse(oFetch(owedHour, 'payslipDate')).toDate();
        const sPayslipDate = oFetch(owedHour, 'payslipDate');
        const payslipRotaWeek = RotaWeek.fromDate({
          dCalendarDate: dPaySlipDate,
        });
        const payslipWeekStartDate = payslipRotaWeek.startDate();
        const isOutsideFinanceReportOwedHour = financeReportWeekStartDate.getTime() !== payslipWeekStartDate.getTime();
        const sOwedHourDate = oFetch(owedHour, 'date');
        const dOwedHourDate = sOwedHourDate ? safeMoment.uiDateParse(sOwedHourDate).toDate() : null;

        return (
          <li
            key={oFetch(owedHour, 'id')}
            style={{ listStyleType: 'none' }}
          >
            <div style={{ textAlign: 'left' }}>
              <span>
                { args.showDates ? <b>{`${format(dOwedHourDate, COMMON_DATE_FORMAT)} `}</b> : null}
                { args.showDates ? null : <span><FaMoneyBillTrendUp style={{ display: 'inline' }} /> </span> }
                { `${minutesToFormattedHoursAndMinutes(oFetch(owedHour, 'minutes'))} ` }
              </span>
              { dOwedHourDate && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={legacyAppRoutes.staffMemberHoursOverview({
                      staffMemberId: oFetch(args, 'staffMemberId'),
                      dDate: dOwedHourDate,
                    })}
                  >
                  {dOwedHourStartsAt && {formattedStartsAt}-{formattedEndsAt} }
                  {!dOwedHourStartsAt && 'Times not tracked'}
                </a>
              )}
              { !dOwedHourDate && dOwedHourStartsAt && `${formattedStartsAt}-${formattedEndsAt}` }
              { !dOwedHourDate && !dOwedHourStartsAt && 'Times not tracked' }
            </div>
              { isOutsideFinanceReportOwedHour && (
                <div>
                  <span>Paid on week starting {format(payslipWeekStartDate, UI_DATE_FORMAT)} </span>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={legacyAppRoutes.mossFinanceReports({
                      sStartDate: sPayslipDate,
                      venueId: oFetch(owedHour, 'effectiveFinanceReportVenueId'),
                    })}
                  >
                    View
                  </a>
                </div>
              )}
          </li>
        );
      })}
    </ul>
  );
}

function ReportItem(props) {
  const report = oFetch(props, 'report');
  const isFinanceReportWeekFinished = oFetch(props, 'isFinanceReportWeekFinished');
  const sStartDate = oFetch(props, 'startDate');
  const dStartDate = safeMoment.uiDateParse(sStartDate).toDate();
  const financeReportWeek  = RotaWeek.fromDate({
    dCalendarDate: dStartDate,
  });
  const mondayOwedHours = oFetch(report, 'mondayOwedHours');
  const tuesdayOwedHours = oFetch(report, 'tuesdayOwedHours');
  const wednesdayOwedHours = oFetch(report, 'wednesdayOwedHours');
  const thursdayOwedHours = oFetch(report, 'thursdayOwedHours');
  const fridayOwedHours = oFetch(report, 'fridayOwedHours');
  const saturdayOwedHours = oFetch(report, 'saturdayOwedHours');
  const sundayOwedHours = oFetch(report, 'sundayOwedHours');
  const outsideWeekOwedHours = oFetch(report, 'outsideWeekOwedHours');
  const allOwedHours = [];
  [
    mondayOwedHours,
    tuesdayOwedHours,
    wednesdayOwedHours,
    thursdayOwedHours,
    fridayOwedHours,
    saturdayOwedHours,
    sundayOwedHours,
    outsideWeekOwedHours,
  ].forEach((groupedOwedHours) => {
    groupedOwedHours.forEach((groupedOwedHour) => {
      allOwedHours.push(groupedOwedHour);
    });
  });

  function renderOwedHoursTooltip(content) {
    if (content == null) {
      return null;
    }

    return (
      <Tooltip
        arrow
        theme="light"
        position="right"
        interactive
        html={content}
      >
        <span className="boss-table__tooltip">
          <span className="boss-tooltip boss-tooltip_role_alert">
            <FaMoneyBillTrendUp />
          </span>
        </span>
      </Tooltip>
    );
  }

  function renderWeekDaysCells() {
    const mondayHoursCount = utils.round(oFetch(report, 'mondayHoursCount'), 2);
    const tuesdayHoursCount = utils.round(oFetch(report, 'tuesdayHoursCount'), 2);
    const wednesdayHoursCount = utils.round(oFetch(report, 'wednesdayHoursCount'), 2);
    const thursdayHoursCount = utils.round(oFetch(report, 'thursdayHoursCount'), 2);
    const fridayHoursCount = utils.round(oFetch(report, 'fridayHoursCount'), 2);
    const saturdayHoursCount = utils.round(oFetch(report, 'saturdayHoursCount'), 2);
    const sundayHoursCount = utils.round(oFetch(report, 'sundayHoursCount'), 2);

    const mondayOwedHours = oFetch(report, 'mondayOwedHours');
    const tuesdayOwedHours = oFetch(report, 'tuesdayOwedHours');
    const wednesdayOwedHours = oFetch(report, 'wednesdayOwedHours');
    const thursdayOwedHours = oFetch(report, 'thursdayOwedHours');
    const fridayOwedHours = oFetch(report, 'fridayOwedHours');
    const saturdayOwedHours = oFetch(report, 'saturdayOwedHours');
    const sundayOwedHours = oFetch(report, 'sundayOwedHours');

    const mondayHasDeletedHours = oFetch(report, 'mondayHasDeletedHours');
    const tuesdayHasDeletedHours = oFetch(report, 'tuesdayHasDeletedHours');
    const wednesdayHasDeletedHours = oFetch(report, 'wednesdayHasDeletedHours');
    const thursdayHasDeletedHours = oFetch(report, 'thursdayHasDeletedHours');
    const fridayHasDeletedHours = oFetch(report, 'fridayHasDeletedHours');
    const saturdayHasDeletedHours = oFetch(report, 'saturdayHasDeletedHours');
    const sundayHasDeletedHours = oFetch(report, 'sundayHasDeletedHours');

    const staffMemberId = oFetch(props, 'report.staffMemberId');
    const sWeekDates = oFetch(props, 'weekDates');
    const hoursPending = oFetch(report, 'hoursPending');
    const sDaysNeedingCompletion = oFetch(report, 'daysNeedingCompletion');

    return [
      [mondayHoursCount, mondayOwedHours, mondayHasDeletedHours],
      [tuesdayHoursCount, tuesdayOwedHours, tuesdayHasDeletedHours],
      [wednesdayHoursCount, wednesdayOwedHours, wednesdayHasDeletedHours],
      [thursdayHoursCount, thursdayOwedHours, thursdayHasDeletedHours],
      [fridayHoursCount, fridayOwedHours, fridayHasDeletedHours],
      [saturdayHoursCount, saturdayOwedHours, saturdayHasDeletedHours],
      [sundayHoursCount, sundayOwedHours, sundayHasDeletedHours],
    ].map((dayHours, index) => {
      const [dayHoursCount, dayOwedHours, hasDeletedHours] = dayHours;
      const dayHasOwedHours = dayOwedHours.length > 0;

      const sWeekDate = sWeekDates[index];
      const dWeekDate = safeMoment.uiDateParse(sWeekDate).toDate();

      const owedHourTooltipContent = renderOwedHoursTooltipContent({
        showDates: false,
        owedHours: dayOwedHours,
        financeReportWeek,
        staffMemberId,
      });

      if (hoursPending === true && sDaysNeedingCompletion[sWeekDate]) {
        const tooltipContent = (
          <span>
            <a
              target="_blank"
              rel="noreferrer"
              href={legacyAppRoutes.staffMemberHoursOverview({ staffMemberId, dDate: dWeekDate })}
            >
              {sDaysNeedingCompletion[sWeekDate].join(', ')}
            </a>
          </span>
        );
        return (
          <div key={index} className={getCellClassName({ alertStyles: true })} style={cellStyle}>
            <a
              href={legacyAppRoutes.staffMemberHoursOverview({ staffMemberId, dDate: dWeekDate })}
              className={`${getTextClassName({ alertStyles: true })} boss-table__link`}
            >
              { dayHoursCount }
              { dayHasOwedHours && renderOwedHoursTooltip(owedHourTooltipContent) }
            </a>
            {renderQuestionMarkTooltip(tooltipContent)}
          </div>
        );
      } else {
        return (
          <div key={index} className={getCellClassName({ alertStyles: false })} style={cellStyle}>
            <p style={{ marginBottom: 0 }} className={getTextClassName({ alertStyles: false })}>
              <a
                href={legacyAppRoutes.staffMemberHoursOverview({ staffMemberId, dDate: dWeekDate })}
                className={`${getTextClassName({ alertStyles: false })} boss-table__link`}
              >
                { dayHoursCount }
                { dayHasOwedHours && renderOwedHoursTooltip(owedHourTooltipContent) }
              </a>
            </p>
            {hasDeletedHours && (
              <div className="boss-corner boss-corner_position_bottom-left boss-corner_color_accent-red-saturated">
                <span className="boss-corner__icon boss-corner__icon_trash" />
              </div>
            )}
          </div>
        );
      }
    });
  }

  function getCellClassName(params) {
    const alertStyles = oFetch(params, 'alertStyles');
    return classNames({
      'boss-table__cell': true,
      'boss-table__cell_state_alert': alertStyles,
    });
  }

  function getTextClassName(params) {
    const alertStyles = oFetch(params, 'alertStyles');
    return classNames({
      'boss-boss-table__text': true,
      'boss-table__text_state_alert': alertStyles,
    });
  }

  function renderQuestionMarkTooltip(content) {
    return (
      <Tooltip arrow theme="light" position="right" interactive html={content}>
        <span className="boss-table__tooltip">
          <span className="boss-tooltip boss-tooltip_role_alert">
            <span className="boss-tooltip__icon" />
          </span>
        </span>
      </Tooltip>
    );
  }

  function renderNoteTooltip(note) {
    return (
      <Tooltip
        arrow
        theme="light"
        position="bottom"
        trigger="click"
        interactive
        html={
          <div className="boss-overview">
            <p className="boss-overview__text" style={{ whiteSpace: 'pre-line' }}>
              {note}
            </p>
          </div>
        }
      >
        <span className="boss-table__tooltip">
          <p className="boss-table__text boss-table__text_role_action">
            <span className="boss-indicator boss-table__indicator">
              <span className="boss-indicator__icon boss-indicator__icon_envelope" />
            </span>
          </p>
        </span>
      </Tooltip>
    );
  }

  function errorTooltipContent(params) {
    const validationErrors = oFetch(params, 'validationErrors');
    return (
      <ul>
        {validationErrors.map((text, index) => (
          <li key={`val-err-li-${index}`}>{text}</li>
        ))}
      </ul>
    );
  }

  function renderSageId(props) {
    const [sageId, allowNoSageId, staffMemberId] = oFetch(props, 'sageId', 'allowNoSageId', 'staffMemberId');
    if (sageId) {
      return (
        <div className="boss-table__cell">
          <div className="boss-table__text">{sageId}</div>
        </div>
      );
    }
    if (allowNoSageId) {
      return (
        <div className="boss-table__cell">
          <div className="boss-table__text" />
        </div>
      );
    } else {
      return (
        <div className="boss-table__cell boss-table__cell_state_alert">
          <a href={legacyAppRoutes.staffMember(staffMemberId)}>
            <p className="boss-table__text boss-table__text_state_alert">
              <span className="boss-table__tooltip">
                <span className="boss-tooltip boss-tooltip_role_alert">
                  <span className="boss-tooltip__icon" />
                </span>
              </span>
            </p>
          </a>
        </div>
      );
    }
  }

  function getNetWagesIconColorClass(params) {
    let result = null;
    const hasNetWages = oFetch(params, 'hasNetWages');
    const paymentWeekPublished = oFetch(params, 'paymentWeekPublished');
    if (!(hasNetWages && paymentWeekPublished)) {
      return result;
    }

    const wagesPending = oFetch(params, 'wagesPending');
    const wagesReceived = oFetch(params, 'wagesReceived');

    if (wagesPending) {
      result = 'boss-indicator_color_accent-red';
    } else if (wagesReceived) {
      result = 'boss-indicator_color_accent-green';
    }

    return result;
  }

  function getNetWageIconClass(params) {
    let result = null;
    const hasNetWages = oFetch(params, 'hasNetWages');
    const paymentWeekPublished = oFetch(params, 'paymentWeekPublished');
    if (!(hasNetWages && paymentWeekPublished)) {
      return result;
    }

    const wagesPending = oFetch(params, 'wagesPending');
    const wagesReceived = oFetch(params, 'wagesReceived');

    if (wagesPending) {
      result = 'boss-indicator__icon_hour-glass boss-indicator__icon_size_xs';
    } else if (wagesReceived) {
      result = 'boss-indicator__icon_check-bold boss-indicator__icon_size_s';
    }

    return result;
  }

  function getNetWagesValueText(params) {
    const [hasNetWages, canSeeNetWages, netWagesCents] = oFetch(
      params,
      'hasNetWages',
      'canSeeNetWages',
      'netWagesCents',
    );

    if (hasNetWages && canSeeNetWages) {
      return utils.moneyFormat(netWagesCents / 100.0);
    } else if (hasNetWages && !canSeeNetWages) {
      return '£XX.XX';
    } else {
      return null;
    }
  }

  function renderPayslipAction(params) {
    const onOpenPayslip = oFetch(props, 'onOpenPayslip');

    return (
      <div className="boss-table__actions">
        <button
          onClick={() => onOpenPayslip(params)}
          className="boss-button boss-button_type_extra-small boss-button_role_view-payslip boss-table__action"
        >
          View Payslip
        </button>
      </div>
    );
  }
  const startDate = oFetch(props, 'startDate');
  const endDate = oFetch(props, 'endDate');

  const financeReportId = oFetch(report, 'id');
  const onNoteClick = oFetch(props, 'onNoteClick');
  const [note, noteCreatedBy, noteCreatedAt] = oFetch(report, 'note', 'noteCreatedBy', 'noteCreatedAt');
  const onUncomplete = oFetch(props, 'onUncomplete');
  const bankDetailsPermissions = oFetch(props, 'bankDetailsPermissions');
  const canViewBankDetails = oFetch(bankDetailsPermissions, 'canViewBankDetails');
  const onViewBankDetailsClick = oFetch(props, 'onViewBankDetailsClick');
  const staffMemberHasBankDetails = oFetch(report, 'staffMemberHasBankDetails');
  const mStartDate = safeMoment.uiDateParse(startDate);
  const mEndDate = safeMoment.uiDateParse(endDate);
  const validationErrors = oFetch(props, 'validationErrors');
  const fullName = oFetch(report, 'staffMemberName');
  const weeklyHours = utils.round(oFetch(report, 'weeklyHours'), 2);
  const owedHours = utils.round(oFetch(report, 'owedHours'), 2);

  const acessories = utils.round(oFetch(report, 'acessories'), 2);
  const acessoriesColor = utils.colorizedAmount(acessories);
  const payRateDescription = oFetch(report, 'payRateDescription');
  const totalHoursCount = utils.round(oFetch(report, 'totalHoursCount') + owedHours, 2);
  const total = utils.round(oFetch(report, 'total'), 2);
  const sickLeaveDaysCount = oFetch(report, 'sickLeaveDaysCount');
  const onMarkCompleted = oFetch(props, 'onMarkCompleted');
  const staffMemberId = oFetch(report, 'staffMemberId');
  const netWagesCents = oFetch(report, 'netWagesCents');
  const hasNetWages = oFetch(report, 'hasNetWages');
  const netWagesStatus = oFetch(report, 'netWagesStatus');
  const canSeeNetWages = oFetch(report, 'canSeeNetWages');
  const sageId = oFetch(report, 'staffMemberSageId');
  const allowNoSageId = oFetch(report, 'staffMemberAllowNoSageId');
  const containsTimeShiftedOwedHours = oFetch(report, 'containsTimeShiftedOwedHours');
  const pendingCalculation = oFetch(report, 'pendingCalculation');
  const staffMemberDisabled = oFetch(report, 'staffMemberDisabled');
  const hoursPending = oFetch(report, 'hoursPending');
  const hasIncompleteHolidays = oFetch(report, 'hasIncompleteHolidays');
  const payslip = oFetch(report, 'payslip');

  const validationErrorsExist = oFetch(validationErrors, 'length') > 0;

  const fullNameCellClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_user-disabled': staffMemberDisabled,
  });
  const fullNameCellTextClassName = classNames({
    'boss-table__text': true,
    'boss-table__text_indicator_accessory': acessories !== 0,
  });
  const rowClassName = classNames({
    'boss-table__row': true,
    'boss-table__row_state_alert': hoursPending || validationErrorsExist || hasIncompleteHolidays,
    'boss-table__row_state_pre-calculated': pendingCalculation,
  });
  const owedHoursClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_clock-warning': owedHours !== 0 && containsTimeShiftedOwedHours,
  });
  const sickLeaveDaysCountClassName = classNames({
    'boss-table__cell': true,
  });

  const wagesZero = netWagesStatus === 'zero';
  const wagesPending = netWagesStatus === 'pending';
  const wagesReceived = netWagesStatus === 'received';
  const paymentWeekPublished = oFetch(props, 'paymentWeekPublished');
  const netWagesValueText = getNetWagesValueText({
    hasNetWages,
    canSeeNetWages,
    netWagesCents,
  });
  const netWagesSectionStylesClasses = classNames({
    'boss-table__text_role_important':
      hasNetWages && (!paymentWeekPublished || wagesZero || wagesPending || wagesReceived),
    'boss-table__text_state_alert': hasNetWages && paymentWeekPublished && wagesPending,
    'boss-table__text_state_success': hasNetWages && paymentWeekPublished && wagesReceived,
  });
  const netWagesIconAccentColorClass = getNetWagesIconColorClass({
    hasNetWages,
    paymentWeekPublished,
    wagesPending,
    wagesReceived,
  });
  const netWagesIconClass = getNetWageIconClass({
    hasNetWages,
    paymentWeekPublished,
    wagesPending,
    wagesReceived,
  });

  return (
    <div id={financeReportId} className={rowClassName}>
      <div className={fullNameCellClassName}>
        <p className={fullNameCellTextClassName}>
          <a
            href={legacyAppRoutes.staffMember(staffMemberId)}
            className="boss-table__link"
            style={{ textTransform: 'capitalize' }}
          >
            {fullName}
          </a>
        </p>
      </div>
      {renderSageId({ sageId, allowNoSageId, staffMemberId })}
      {renderWeekDaysCells()}
      <div className={getCellClassName({ alertStyles: false })} style={cellStyle}>
        <p className={getTextClassName({ alertStyles: false })}>{weeklyHours}</p>
      </div>
      {owedHours === 0 ? (
        <div className={owedHoursClassName} style={cellStyle}>
          <p className={getTextClassName({ alertStyles: false })}>
            {owedHours}
            { oFetch(allOwedHours, 'length') > 0 && (
              renderOwedHoursTooltip(
                renderOwedHoursTooltipContent({
                  owedHours: allOwedHours,
                  showDates: true,
                  financeReportWeek,
                  staffMemberId
                })
              )
            )}
          </p>
        </div>
      ) : (
        <div className={owedHoursClassName} style={cellStyle}>
          <a
            href={legacyAppRoutes.staffMemberOwedHours({
              staffMemberId: staffMemberId,
              mPayslipStartDate: mStartDate,
              mPayslipEndDate: mEndDate,
            })}
            className={`${getTextClassName({ alertStyles: false })} boss-table__link`}
          >
            {owedHours}
            { oFetch(allOwedHours, 'length') > 0 && (
              renderOwedHoursTooltip(
                renderOwedHoursTooltipContent({
                  owedHours: allOwedHours,
                  showDates: true,
                  financeReportWeek,
                  staffMemberId
                })
              )
            )}
          </a>
        </div>
      )}

      <div className={getCellClassName({ alertStyles: false })} style={cellStyle}>
        {acessories === 0 ? (
          <p className={getTextClassName({ alertStyles: false })}>{utils.moneyFormat(acessories)}</p>
        ) : (
          <a
            href={legacyAppRoutes.staffMemberAccessories({ staffMemberId })}
            className={getTextClassName({ alertStyles: false })}
            style={{ color: acessoriesColor }}
          >
            {utils.moneyFormat(acessories)}
          </a>
        )}
      </div>
      <div className={getCellClassName({ alertStyles: false })} style={cellStyle}>
        <p className={getTextClassName({ alertStyles: false })}>{payRateDescription}</p>
      </div>
      <div className={getCellClassName({ alertStyles: false })} style={cellStyle}>
        <p
          className={`${getTextClassName({
            alertStyles: false,
          })} boss-table__text_role_important`}
        >
          {totalHoursCount}
        </p>
      </div>
      <PaidHolidaysColumn financeReport={report} startDate={startDate} endDate={endDate} />
      {sickLeaveDaysCount === 0 && (
        <div className={sickLeaveDaysCountClassName} style={cellStyle}>
          <p className={getTextClassName({ alertStyles: false })}>{sickLeaveDaysCount}</p>
        </div>
      )}
      {sickLeaveDaysCount !== 0 && (
        <div className={sickLeaveDaysCountClassName} style={cellStyle}>
          <a
            href={legacyAppRoutes.staffMemberProfileHolidaysTabFromFinanceReport({
              staffMemberId: staffMemberId,
              mPayslipStartDate: mStartDate,
              mPayslipEndDate: mEndDate,
            })}
            className={`${getTextClassName({ alertStyles: false })} boss-table__link`}
          >
            {sickLeaveDaysCount}
          </a>
        </div>
      )}
      <div className={getCellClassName({ alertStyles: false })} style={cellStyle}>
        <p className={getTextClassName({ alertStyles: false })} style={total < 0 ? { color: 'red' } : {}}>
          {utils.moneyFormat(total)}
        </p>
      </div>
      <div className="boss-table__cell">
        {hasNetWages && (
          <p className={`boss-table__text ${netWagesSectionStylesClasses}`}>
            {paymentWeekPublished && wagesPending && (
              <span className="boss-table__text-indicator">
                <span className={`boss-indicator ${netWagesIconAccentColorClass}`}>
                  <span className={`boss-indicator__icon ${netWagesIconClass}`} />
                </span>
              </span>
            )}
            <span>
              {validationErrorsExist && renderQuestionMarkTooltip(errorTooltipContent({ validationErrors }))}
              {netWagesValueText}
            </span>
          </p>
        )}
        {payslip && renderPayslipAction({ payslip, fullName })}
      </div>
      <StatusColumn
        isFinanceReportWeekFinished={isFinanceReportWeekFinished}
        onMarkCompleted={onMarkCompleted}
        onUncomplete={onUncomplete}
        financeReport={report}
      />
      <div className={'boss-table__cell'}>
        {!staffMemberHasBankDetails && <p className="boss-table__text">N/A</p>}
        {staffMemberHasBankDetails && (
          <Fragment>
            <p className="boss-table__text">
              <span className="boss-indicator boss-table__indicator">
                <span className={'boss-indicator__icon boss-indicator__icon_check-bold'} />
              </span>
            </p>
            {canViewBankDetails && (
              <div className="boss-table__actions">
                <button
                  onClick={() => onViewBankDetailsClick(report)}
                  className="boss-button boss-button_type_extra-small boss-button_role_view-bank-details boss-table__action"
                >
                  View
                </button>
              </div>
            )}
          </Fragment>
        )}
      </div>
      <div className="boss-table__cell">
        {note && renderNoteTooltip(note)}
        <div className="boss-table__actions">
          {note && (
            <button
              onClick={() => onNoteClick({ financeReport: report, edit: !!note })}
              className="boss-button boss-button_type_extra-small boss-button_role_primary-light boss-table__action"
            >
              Edit Note
            </button>
          )}
          {!note && (
            <button
              onClick={() => onNoteClick({ financeReport: report, edit: !!note })}
              className="boss-button boss-button_type_extra-small boss-button_role_primary-light boss-table__action"
            >
              Add Note
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

ReportItem.propTypes = {
  report: PropTypes.shape({
    staffMemberId: PropTypes.number.isRequired,
    mondayHoursCount: PropTypes.number.isRequired,
    tuesdayHoursCount: PropTypes.number.isRequired,
    wednesdayHoursCount: PropTypes.number.isRequired,
    thursdayHoursCount: PropTypes.number.isRequired,
    fridayHoursCount: PropTypes.number.isRequired,
    saturdayHoursCount: PropTypes.number.isRequired,
    sundayHoursCount: PropTypes.number.isRequired,
    weeklyHours: PropTypes.number.isRequired,
    owedHours: PropTypes.number.isRequired,
    totalHoursCount: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    paidHolidayDaysCount: PropTypes.number.isRequired,

    staffMemberName: PropTypes.string.isRequired,
    acessories: PropTypes.number.isRequired,
    payRateDescription: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  weekDates: PropTypes.array.isRequired,
  onOpenPayslip: PropTypes.func.isRequired,
  onMarkCompleted: PropTypes.func.isRequired,
};

export default ReportItem;

export const PureJSReportItem = pureToJs(ReportItem);
