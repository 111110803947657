import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReportTableHeader from './report-table-header';

function ReportList(props) {
  const [isOpened, setIsOpened] = useState(true);
  const itemRenderer = oFetch(props, 'itemRenderer');
  const staffType = oFetch(props, 'staffType');
  const paymentDataByStaffMemberId = oFetch(props, 'paymentDataByStaffMemberId');

  const staffTypeJS = staffType.toJS();
  const startDate = oFetch(props, 'startDate');
  const { color, name } = staffType.toJS();

  function toggleDropDown() {
    setIsOpened(!isOpened);
  }

  function renderItems() {
    return oFetch(staffTypeJS, 'reports').map(report => {
      const staffMemberId = oFetch(report, 'staffMemberId');
      const paymentData = oFetch(paymentDataByStaffMemberId, staffMemberId);

      return React.cloneElement(itemRenderer(report, paymentData), {
        key: oFetch(report, 'frontendId').toString(),
      });
    });
  }

  return (
    <section className="boss-board boss-board_context_stack boss-board_role_payroll-report">
      <header className="boss-board__header">
        <h2 className="boss-board__title boss-board__title_size_medium">
          <span
            className="boss-button boss-button_type_small boss-button_type_no-behavior boss-board__title-label"
            style={{ backgroundColor: color }}
          >
            {name}
          </span>
        </h2>
        <div className="boss-board__button-group">
          <button
            type="button"
            className={`boss-board__switch ${isOpened ? 'boss-board__switch_state_opened' : ''}`}
            onClick={toggleDropDown}
          />
        </div>
      </header>
      <Collapse
        isOpened={isOpened}
        className={`boss-board__content ${isOpened ? 'boss-board__content_state_opened' : ''}`}
        style={{ display: isOpened ? 'block' : 'none' }}
      >
        <div className="boss-board__content-inner">
          <div className="boss-board__table">
            <div className="boss-table boss-table_page_payroll-reports">
              <ReportTableHeader
                staffTypeName={name}
                startDate={startDate}
              />
              <div className='boss-table__group boss-table__group_role_main'>
                <div
                  className='boss-table__group-scroll syncscroll dragscroll'
                  name={`table-${name}`}
                >
                  {renderItems()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </section>
  );
}

ReportList.propTypes = {
  staffType: ImmutablePropTypes.map.isRequired,
  startDate: PropTypes.string.isRequired,
  itemRenderer: PropTypes.func.isRequired,
  paymentDataByStaffMemberId: PropTypes.object.isRequired,
};

export default ReportList;
