import React, { useState, useMemo } from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { parseISO } from 'date-fns';
import safeMoment from '@/lib/safe-moment';
import AsyncButton from 'react-async-button';
import { RotaDate, BOSS_APP_TYPE, MOSS_APP_TYPE, RotaDateUIUtils } from '@/lib/rota-date';
import {
  ClockOutButton,
  Notes,
  EventsGraph,
  HoursAcceptancePeriodList,
  OvertimeReasons,
  RightSideHeader,
  RightSideInfoSection,
  PendingHoursAcceptancePeriodItem,
  AcceptedHoursAcceptancePeriodItem,
} from '.';

export default function RightSide(props) {
  const [clockOutErrors, setClockOutErrors] = useState(null);
  const hasClockOutErrors = clockOutErrors !== null;

  const status = oFetch(props, 'status');
  const date = oFetch(props, 'date');
  const pageType = oFetch(props, 'pageType');
  const clockingDay = oFetch(props, 'clockingDay');
  const onClockOut = oFetch(props, 'onClockOut');
  const onAccept = oFetch(props, 'onAccept');
  const onUnaccept = oFetch(props, 'onUnaccept');
  const handleAddHoursAcceptancePeriod = oFetch(props, 'handleAddHoursAcceptancePeriod');
  const onUndoHoursAcceptancePeriod = oFetch(props, 'onUndoHoursAcceptancePeriod');
  const activeSpecialSecurityPayrates = oFetch(props, 'activeSpecialSecurityPayrates');
  const mossHourTags = oFetch(props, 'mossHourTags');
  const permissions = oFetch(props, 'permissions');
  const currentDay = oFetch(props, 'currentDay');
  const onDeleteHoursAcceptancePeriod = oFetch(props, 'onDeleteHoursAcceptancePeriod');

  const formattedAcceptedHoursAcceptanceTime = oFetch(clockingDay, 'formattedAcceptedHoursAcceptanceTime');
  const formattedRotaedTime = oFetch(clockingDay, 'formattedRotaedTime');
  const formattedClockedTime = oFetch(clockingDay, 'formattedClockedTime');
  const formattedDate = oFetch(clockingDay, 'formattedDate');
  const venue = oFetch(clockingDay, 'venue');
  const isClockedIn = oFetch(clockingDay, 'isClockedIn');
  const canAddShifts = oFetch(clockingDay, 'canAddShifts');
  const effectiveStaffMember = oFetch(clockingDay, 'effectiveStaffMember');
  const hoursAcceptancePeriods = oFetch(clockingDay, 'hoursAcceptancePeriods');
  const notes = oFetch(clockingDay, 'notes');
  const overtimeReasons = oFetch(clockingDay, 'overtimeReasons');
  const clockInPeriods = oFetch(clockingDay, 'clockInPeriods');
  const rotaShifts = oFetch(clockingDay, 'rotaShifts');
  const clockInEvents = oFetch(clockingDay, 'clockInEvents');
  const mDate = oFetch(clockingDay, 'mDate');

  const appType = oFetch(effectiveStaffMember, 'appType');
  const effectiveStaffMemberId = oFetch(effectiveStaffMember, 'id');

  let formattedAcceptedTimeDiff = null;
  if (appType === BOSS_APP_TYPE) {
    formattedAcceptedTimeDiff = oFetch(clockingDay, 'formattedAcceptedHoursAcceptanceTimeLeft');
  } else if (appType === MOSS_APP_TYPE) {
    formattedAcceptedTimeDiff = oFetch(clockingDay, 'formattedAcceptedVsClockedTimeDiff');
  } else {
    throw new Error(`unsupported appType encountered: ${appType}`);
  }

  const isNotesExist = notes.length !== 0;
  const overtimeReasonsExist = overtimeReasons.length > 0;

  const rotaDate = RotaDate.fromDate({
    dCalendarDate: mDate.toDate(),
    appType,
  });

  const venueName = oFetch(venue, 'name');
  const venueId = oFetch(venue, 'id');

  const normalizedClockInEvents = useMemo(() => {
    return clockInEvents.map((event, index) => {
      const sAt = oFetch(event, 'at');
      const dAt = parseISO(sAt);
      const timeOffset = RotaDateUIUtils.fromTime({ dTime: dAt, appType }).dGetHoursSinceStartOfDay(dAt);

      return {
        ...event,
        dAt,
        timeOffset,
        index,
        isFirst: index === 0,
        isLast: clockInEvents.length - 1 === index,
        type: oFetch(event, 'eventType'),
        authenticationMethod: oFetch(event, 'authenticationMethod'),
        s3AttemptUrl: oFetch(event, 's3AttemptUrl'),
        creatorFullName: oFetch(event, 'creatorFullName'),
        takenAt: oFetch(event, 'takenAt'),
        isManagerEvent: oFetch(event, 'isManagerEvent'),
      };
    }).sort((a, b) => a.dAt - b.dAt);
  }, [clockInEvents]);

  function renderDeletedHoursAcceptancePeriod(params) {
    const [hoursAcceptancePeriod] = oFetch(
      params,
      'hoursAcceptancePeriod',
      'permissions',
    );
    const [formattedTime, deletedBy, deletedAt] = oFetch(
      hoursAcceptancePeriod,
      'formattedTime',
      'deletedBy',
      'deletedAt',
    );

    function formatDateTime(dateTime) {
      return safeMoment.iso8601Parse(dateTime).format(utils.commonDateFormatWithTime());
    }

    return (
      <div className="boss-hrc__shift">
        <div className="boss-time-shift boss-time-shift_updated boss-time-shift_state_deleted">
          <form
            className="boss-time-shift__form"
          >
            <div className="boss-time-shift__log boss-time-shift__log_state_deleted">
              <div className="boss-time-shift__actions">
                <p className="boss-time-shift__status">
                  <span className="boss-time-shift__status-count">{formattedTime} Deleted</span>
                  {deletedBy && deletedAt && (
                    <span className="boss-time-shift__status-meta">
                      by {deletedBy} at {formatDateTime(deletedAt)}
                    </span>
                  )}
                </p>
                <AsyncButton
                  className="boss-button boss-button_role_recover boss-time-shift__button boss-time-shift__button_role_recover-shift"
                  text="Undo"
                  pendingText="Undoing ..."
                  onClick={() => onUndoHoursAcceptancePeriod({ hoursAcceptancePeriod })}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  function renderClockOutErrors(params) {
    const clockOutErrors = oFetch(params, 'clockOutErrors');
    return (
      <div className="boss-time-shift__error boss-time-shift__error_role_general">
        <p className="boss-time-shift__error-text boss-time-shift__error-text_role_title">Something went wrong!</p>
        {clockOutErrors.map((error, index) => {
          return (
            <p
              key={index}
              className="boss-time-shift__error-text"
            >{error}</p>
          );
        })}
      </div>
    );
  }

  function renderAcceptedHoursAcceptancePeriod(params) {
    const hoursAcceptancePeriod = oFetch(params, 'hoursAcceptancePeriod');
    const permissions = oFetch(params, 'permissions');
    const canBypassPeriodsTimeLimits = oFetch(permissions, 'canBypassPeriodsTimeLimits');

    return (
      <AcceptedHoursAcceptancePeriodItem
        mossHourTags={mossHourTags}
        onUnaccept={onUnaccept}
        hoursAcceptancePeriod={hoursAcceptancePeriod}
        canBypassPeriodsTimeLimits={canBypassPeriodsTimeLimits}
      />
    );
  }

  function renderPendingHoursAcceptancePeriod(params) {
    const [currentDay, hoursAcceptancePeriod, permissions] = oFetch(
      params,
      'currentDay',
      'hoursAcceptancePeriod',
      'permissions',
    );
    const canBypassPeriodsTimeLimits = oFetch(permissions, 'canBypassPeriodsTimeLimits');

    return (
      <PendingHoursAcceptancePeriodItem
        mossHourTags={mossHourTags}
        effectiveStaffMember={effectiveStaffMember}
        clockingDay={clockingDay}
        hoursAcceptancePeriod={hoursAcceptancePeriod}
        onDeletePeriod={onDeleteHoursAcceptancePeriod}
        onAccept={onAccept}
        activeSpecialSecurityPayrates={activeSpecialSecurityPayrates}
        canBypassPeriodsTimeLimits={canBypassPeriodsTimeLimits}
        currentDay={currentDay}
      />
    );
  }

  return (
    <div className="boss-hrc__main">
      <RightSideHeader
        status={status}
        date={formattedDate}
        venueName={venueName}
        pageType={pageType}
      />
      <div className="boss-hrc__content">

        <RightSideInfoSection
          formattedAcceptedHoursAcceptanceTime={formattedAcceptedHoursAcceptanceTime}
          formattedRotaedTime={formattedRotaedTime}
          formattedClockedTime={formattedClockedTime}
          formattedAcceptedTimeDiff={formattedAcceptedTimeDiff}
          normalizedClockInEvents={normalizedClockInEvents}
        />
        <EventsGraph
          clockingDay={clockingDay}
          appType={appType}
          clockedClockInPeriods={clockInPeriods}
          hoursAcceptancePeriods={hoursAcceptancePeriods}
          rotaedShifts={rotaShifts}
          rotaDate={rotaDate}
          normalizedClockInEvents={normalizedClockInEvents}
        />
        {isNotesExist && <Notes notes={notes} />}
        {overtimeReasonsExist && <OvertimeReasons overtimeReasons={overtimeReasons} />}
        {!isClockedIn && (
          <HoursAcceptancePeriodList
            acceptedPeriodRenderer={renderAcceptedHoursAcceptancePeriod}
            pendingPeriodRenderer={renderPendingHoursAcceptancePeriod}
            deletedPeriodRenderer={renderDeletedHoursAcceptancePeriod}
            hoursAcceptancePeriods={hoursAcceptancePeriods}
            permissions={permissions}
            currentDay={currentDay}
          />
        )}
        {hasClockOutErrors && renderClockOutErrors({ clockOutErrors })}
        {isClockedIn && (
          <ClockOutButton
            onClockOut={() => onClockOut({ mDate, effectiveStaffMemberId, venueId, setClockOutErrors, applicationType: appType })}
          />
        )}
        {currentDay && !isClockedIn && canAddShifts && (
          <div className="boss-hrc__controls">
            <button
              type="button"
              onClick={handleAddHoursAcceptancePeriod}
              className="boss-button boss-button_role_add boss-hrc__button boss-hrc__button_role_add"
            >
              Add Shift
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
