import React, { useState } from 'react';
import oFetch from 'o-fetch';
import humanize from 'string-humanize';
import { Collapse } from 'react-collapse';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { appRoutes } from '@/lib/legacy-routes';
import { InviteLinkedContent } from './invite-linked-content';
import { InviteNotLinkedContent } from './invite-not-linked-content';
import Spinner from '@/components/spinner';

import { OPEN_STATUS, REVOKED_STATUS, ACCEPTED_STATUS } from '../constants';

export function InviteRow(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const invite = oFetch(props, 'invite');
  const onRevokeInvite = oFetch(props, 'onRevoke');
  const onResendInvite = oFetch(props, 'onResend');
  const inviteId = oFetch(invite, 'id');
  const currentState = oFetch(invite, 'currentState');
  const isLinked = oFetch(invite, 'isLinked');
  const linkedStaffMemberId = oFetch(invite, 'linkedStaffMemberId');
  const email = oFetch(invite, 'email');
  const role = oFetch(invite, 'role');
  const inviterFullName = oFetch(invite, 'inviterFullName');
  const createdAt = oFetch(invite, 'createdAt');
  const isInviteOpen = currentState === OPEN_STATUS;
  const isInviteRevoked = currentState === REVOKED_STATUS;
  const isInviteAccepted = currentState === ACCEPTED_STATUS;

  const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.commonDateFormatCalendar());

  const arrowButtonClassNames = cn('boss-check__dropdown-link boss-check__dropdown-link_type_icon', {
    'boss-check__dropdown-link_state_closed': !isOpen,
  });

  const statusClassNames = cn('boss-check__marker boss-check__marker_position_before', {
    'boss-check__marker_status_revoked': isInviteRevoked,
    'boss-check__marker_status_open': isInviteOpen,
    'boss-check__marker_status_accepted': isInviteAccepted,
  });

  const rawUnlinkedInviteType = oFetch(invite, 'unlinkedInviteType');
  let unlinkedInviteTypeDescription = null;
  if (!isLinked) {
    if (rawUnlinkedInviteType === "boss") {
      unlinkedInviteTypeDescription = "Boss";
    } else if (rawUnlinkedInviteType === "moss") {
      unlinkedInviteTypeDescription = "Moss";
    } else {
      throw new Error(`unsupported invite type supplied: ${rawUnlinkedInviteType}`)
    }
  }

  function toggle() {
    setIsOpen(!isOpen);
  }

  function renderInviteDescription() {
    return (
      <div className="boss-check__meta">
        <div className="boss-check__meta-item">
          <p className="boss-check__text">
            <span className="boss-check__text-light">Created by </span>
            <span className="boss-check__text-marked">{inviterFullName}</span>
            <span className="boss-check__text-light"> at </span>
            <span className="boss-check__text-marked">{formattedCreatedAt}</span>
          </p>
        </div>
        {isInviteAccepted && (
          <div className="boss-check__meta-item">
            <p className="boss-check__text">
              <span className="boss-check__text-light">Accepted by </span>
              <span className="boss-check__text-marked">{oFetch(invite, 'userFullName')}</span>
              <span className="boss-check__text-light"> at </span>
              <span className="boss-check__text-marked">
                {oFetch(invite, 'acceptedAt')
                  ? safeMoment
                    .iso8601Parse(oFetch(invite, 'acceptedAt'))
                    .format(utils.commonDateFormatCalendar())
                  : 'N/A'}
              </span>
            </p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="boss-check boss-check_role_board boss-check_page_invites-index">
      <div className="boss-check__header">
        <div className="boss-check__header-info">
          <div className={statusClassNames}>
            <span className="boss-check__marker-label">Open</span>
          </div>
          <div className="boss-check__header-group">
            <h3 className="boss-check__title boss-check__title_adjust_wrap">
              {email}
              {isLinked && (
                <span className="boss-check__title-indicator">
                  <a
                    href={appRoutes.staffMemberProfile(linkedStaffMemberId)}
                    className="boss-indicator boss-indicator_role_action boss-indicator_color_accent-blue"
                  >
                    <span className="boss-indicator__icon boss-indicator__icon_chain" />
                  </a>
                </span>
              )}
            </h3>
            {!isLinked && <div className="boss-check__subtitle">
              <span><b>{unlinkedInviteTypeDescription}</b></span>
            </div> }
            <p className="boss-check__subtitle">{humanize(role)}</p>
          </div>
        </div>
        <div className="boss-check__header-actions">
          {isInviteOpen && (
            <span>
              <button
                onClick={() =>{
                  setIsResending(true);
                  onResendInvite({ inviteId }).then(() => setIsResending(false));
                }}
                disabled={isResending}
                type="button"
                className="boss-button boss-button_role_resend boss-button_type_small boss-check__header-action"
              >
                { !isResending && <span>Resend</span> }
                { isResending && <span>Resending...<Spinner /></span> }
              </button>
              <button
                onClick={() => onRevokeInvite(inviteId).then(() => setIsResending(false))}
                type="button"
                className="boss-button boss-button_role_cancel boss-button_type_small boss-check__header-action"
              >
                Revoke
              </button>
            </span>
          )}
        </div>
        <button onClick={toggle} className={arrowButtonClassNames}>
          Toggle Dropdown
        </button>
      </div>
      <Collapse isOpened={isOpen}>
        <div className="boss-check__dropdown" style={{ display: 'block' }}>
          {isLinked && <InviteLinkedContent invite={invite} />}
          {!isLinked && <InviteNotLinkedContent invite={invite} />}
          <div className="boss-check__group boss-check__group_marked">
            <div className="boss-check__group-content">{renderInviteDescription()}</div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
