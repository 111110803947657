import { http, bossRequestHttp } from '@/lib/request-api';
import oFetch from 'o-fetch';

export const inviteUserRequest = params => {
  return http().post(`/api/v1/invites/`, params);
};

export const revokeInviteRequest = params => {
  const inviteId = oFetch(params, 'inviteId');
  return http().post(`/api/v1/invites/${inviteId}/revoke`, params);
};

export function resendInviteRequest(params) {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const inviteId = oFetch(values, 'inviteId');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
      onFailure();
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess();
    },
  }).post(
    `/api/v1/invites/${inviteId}/resend_email`,
    {}
  );
}
