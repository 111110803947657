import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { Tooltip } from 'react-tippy';
import { appRoutes } from '@/lib/legacy-routes';
import oFetch from 'o-fetch';
import { openConfirmationModal, openWarningModal } from '@/components/modals';
import { LegacyCloseOutsideModalWrapper, LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import Dashboard from './dashboard';
import CardList from './card-list';
import ReportList from './report-list';
import { PureJSReportItem } from './report-item';
import Confirm from './confirm';
import PayslipModal from './payslip-modal';
import { BankDetailsViewModal } from './bank-details-view-modal';
import { NoteForm } from './note-form';
import { AreYouSureToWipeBankDetailsModal } from './are-you-sure-to-wipe-bank-details-modal';
import { AreYouSureUncompleteModal } from './are-you-sure-uncomplete-modal';
import { useModal } from '@/components/hooks-components/modals';
import { FINANCE_REPORT_READY_STATUS } from '../constants';
import { sendPayslipEmail } from '../requests';
import { syncscroll } from '@/lib/syncscroll';
import { dragscroll } from '@/lib/dragscroll';

function Page(props) {
  const { openModal } = useModal();
  const financeReportWeek = oFetch(props, 'financeReportWeek');

  useEffect(() => {
    syncscroll();
    dragscroll();
  }, []);

  function handleDateChange(selection) {
    goToFinanceReportsPage({
      startDate: oFetch(selection, 'startUIDate'),
      venueId: oFetch(props, 'venueId'),
    });
  }

  function goToFinanceReportsPage({ startDate, venueId }) {
    window.location.href = appRoutes.financeReports({
      startDate,
      venueId,
    });
  }

  function handleFilterChange(filter) {
    const changePayRateFilter = oFetch(props, 'changePayRateFilter');
    changePayRateFilter({ filter });
  }

  function handleOpenMarkCompletedModal(params) {
    const isNegativeTotal = oFetch(params, 'isNegativeTotal');
    if (isNegativeTotal) {
      openWarningModal({
        submit: handleMarkComplete,
        config: {
          title: 'Are You Sure?',
          text: [
            'You are attempting to complete a finance report with a negative total.',
            'If you choose to proceed all accessory requests will be removed and moved to the next available finance report.',
          ],
          buttonText: 'Continue and move Accessory Requests',
        },
        props: params,
      });
    } else {
      openConfirmationModal({
        submit: handleMarkComplete,
        config: { title: 'WARNING !!!' },
        props: { params },
      })(Confirm);
    }
  }

  function handleMarkComplete(hideModal, values) {
    const markReportCompleted = oFetch(props, 'markReportCompleted');

    return markReportCompleted(values).then(hideModal).catch(hideModal);
  }

  function handleOpenMarkAllCompletedModal(params) {
    const isNegativeTotal = oFetch(params, 'isNegativeTotal');
    if (isNegativeTotal) {
      openWarningModal({
        submit: handleMarkAllComplete,
        config: {
          title: 'Are You Sure?',
          text: [
            'You are attempting to complete a finance report with a negative total.',
            'If you choose to proceed all accessory requests will be removed and moved to the next available finance report.',
          ],
          buttonText: 'Continue and move Accessory Requests',
        },
        props: params,
      });
    } else {
      openConfirmationModal({
        submit: handleMarkAllComplete,
        config: { title: 'WARNING !!!' },
        props: { params },
      })(Confirm);
    }
  }

  function handleMarkAllComplete(hideModal, values) {
    const markReportsCompleted = oFetch(props, 'markReportsCompleted');

    return markReportsCompleted(values).then(hideModal).catch(hideModal);
  }

  function openPayslipModal(params) {
    const [payslip, fullName] = oFetch(params, 'payslip', 'fullName');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: PayslipModal,
      onSubmit: closeModal => {
        return sendPayslipEmail({ payslip, closeModal });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_payslip',
        title: () => {
          return (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{fullName}</span> Payslip
            </div>
          );
        },
      },
      props: {
        payslip,
      },
    });
  }

  function openUpdateNoteModal(params) {
    const [financeReport, edit] = oFetch(params, 'financeReport', 'edit');
    const [note, staffMemberId] = oFetch(financeReport, 'note', 'staffMemberId');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: NoteForm,
      onSubmit: (closeModal, values) => {
        const note = oFetch(values, 'note');
        return updateFinanceReportNote({
          staffMemberId,
          note,
          onSuccess() {
            closeModal();
          },
        });
      },
      config: {
        title: edit ? 'Edit Note' : 'Add Note',
      },
      props: {
        note: note || '',
        edit: edit,
      },
    });
  }

  function openAreYouSureToWipeBankDetailsModal(report) {
    const staffMemberId = oFetch(report, 'staffMemberId');
    const wipeBankDetailsAction = oFetch(props, 'wipeBankDetailsAction');

    return openModal({
      ModalComponent: LegacyCloseOutsideModalWrapper,
      ModalContent: AreYouSureToWipeBankDetailsModal,
      onSubmit: closeModal => {
        return wipeBankDetailsAction({
          values: { staffMemberId },
          onSuccess() {
            closeModal();
          },
        });
      },
      config: {
        title: 'ARE YOU SURE?',
        baseClassName: 'boss-modal-window boss-modal-window_role_confirm',
      },
      props: {},
    });
  }

  function openAreYouSureUncompleteModal(params) {
    const staffMemberId = oFetch(params, 'staffMemberId');

    return openModal({
      ModalComponent: LegacyCloseOutsideModalWrapper,
      ModalContent: AreYouSureUncompleteModal,
      onSubmit: closeModal => {
        return uncompleteFinanceReport({
          staffMemberId,
          onSuccess() {
            closeModal();
          },
        });
      },
      config: {
        title: 'ARE YOU SURE?',
        baseClassName: 'boss-modal-window boss-modal-window_role_warning',
      },
      props: {},
    });
  }

  function openBankDetailsViewModal(report) {
    function renderTooltip() {
      const content = <span>These bank details could not be validated with the bank.</span>;
      return (
        <Tooltip
arrow
theme="dark"
position="bottom"
interactive
html={content}
        >
          <span className="boss-table__tooltip">
            <span className="boss-tooltip boss-tooltip_role_alert">
              <span className="boss-tooltip__icon" />
            </span>
          </span>
        </Tooltip>
      );
    }

    const [staffMemberId, staffMemberName, bankDetailsCouldntBeValidatedByBank] = oFetch(
      report,
      'staffMemberId',
      'staffMemberName',
      'bankDetailsCouldntBeValidatedByBank',
    );
    const getBankDetailsAction = oFetch(props, 'getBankDetailsAction');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: BankDetailsViewModal,
      onSubmit: closeModal => {
        closeModal();
        return openAreYouSureToWipeBankDetailsModal(report);
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_bank-details',
        title: () => {
          return (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{staffMemberName}</span> Bank Details{' '}
              {bankDetailsCouldntBeValidatedByBank === true && renderTooltip()}
            </div>
          );
        },
      },
      props: {
        staffMemberId,
        staffMemberName,
        onGetBankDetails: getBankDetailsAction,
      },
    });
  }

  const startDate = oFetch(props, 'startDate');
  const endDate = oFetch(props, 'endDate');
  const date = oFetch(props, 'date');
  const venueId = oFetch(props, 'venueId');
  const filterType = oFetch(props, 'filterType');
  const weekDates = oFetch(props, 'weekDates');
  const allReady = oFetch(props, 'allReady');
  const permissions = oFetch(props, 'permissions');
  const finishFinanceReportWeek = oFetch(props, 'finishFinanceReportWeek');
  const undoFinanceReportWeek = oFetch(props, 'undoFinanceReportWeek');
  const showPDFDownloadLink = oFetch(permissions, 'showPDFDownloadLink');
  const showCSVDownloadLink = oFetch(permissions, 'showCSVDownloadLink');
  const showDownloadBulkPaymentsFile = oFetch(permissions, 'showDownloadBulkPaymentsFile');
  const staffTypesWithFinanceReports = oFetch(props, 'staffTypesWithFinanceReports');
  const uncompleteFinanceReport = oFetch(props, 'uncompleteFinanceReport');
  const updateFinanceReportNote = oFetch(props, 'updateFinanceReportNote');
  const paymentWeekPublished = oFetch(props, 'paymentWeekPublished');
  const staffPayslipsStats = oFetch(props, 'staffPayslipsStats');
  const financeReportValidationErrors = oFetch(props, 'financeReportValidationErrors');
  const financeReportsOverviewData = oFetch(props, 'financeReportsOverviewData');
  const staffMembersBankDetailsPermissions = oFetch(permissions, 'staffMembersBankDetails');
  const staffMemberIds = staffTypesWithFinanceReports.reduce((acc, staffType) => {
    const staffTypeJS = staffType.toJS();
    return acc.concat(
      0,
      oFetch(staffTypeJS, 'reports')
        .filter(report => oFetch(report, 'status') === FINANCE_REPORT_READY_STATUS)
        .map(report => oFetch(report, 'staffMemberId')),
    );
  }, Immutable.List());

  const reportsTotals = staffTypesWithFinanceReports.reduce((acc, staffType) => {
    const staffTypeJS = staffType.toJS();

    return acc.concat(
      0,
      oFetch(staffTypeJS, 'reports')
        .filter(report => oFetch(report, 'status') === FINANCE_REPORT_READY_STATUS)
        .map(report => oFetch(report, 'total')),
    );
  }, Immutable.List());
  const isNegativeTotal = !!reportsTotals.find(total => total < 0);
  const completedFinanceReportsData = oFetch(props, 'completedFinanceReportsData');

  return (
    <div className="boss-page-main boss-page-main_adjust_finance-reports">
      <Dashboard
        title="Finance Reports"
        date={date}
        financeReportWeek={financeReportWeek}
        startDate={startDate}
        endDate={endDate}
        completedFinanceReportsData={completedFinanceReportsData}
        staffPayslipsStats={staffPayslipsStats}
        venueId={venueId}
        filterType={filterType}
        showDownloadBulkPaymentsFile={showDownloadBulkPaymentsFile}
        onFinishFinanceReportWeek={finishFinanceReportWeek}
        onUndoFinanceReportWeek={undoFinanceReportWeek}
        onDateChange={handleDateChange}
        onFilterChange={handleFilterChange}
        showPDFDownloadLink={showPDFDownloadLink}
        showCSVDownloadLink={showCSVDownloadLink}
        paymentWeekPublished={paymentWeekPublished}
        financeReportsOverviewData={financeReportsOverviewData}
      />
      <CardList
        staffTypesWithFinanceReports={staffTypesWithFinanceReports}
        onMarkAllPageCompleted={() => handleOpenMarkAllCompletedModal({ staffMemberIds, isNegativeTotal })}
        allReady={allReady}
        itemRenderer={staffType => {
          const staffTypeJS = staffType.toJS();
          const reportsJS = oFetch(staffTypeJS, 'reports');

          const staffMemberIds = reportsJS
            .filter(report => {
              return oFetch(report, 'status') === 'ready';
            })
            .map(report => oFetch(report, 'staffMemberId'));

          const isNegativeTotal = !!reportsJS.find(report => oFetch(report, 'total') < 0);
          const isFinanceReportWeekFinished = oFetch(financeReportWeek, 'isFinished');

          return (
            <ReportList
              staffType={staffType}
              startDate={startDate}
              onMarkAllCompleted={() => handleOpenMarkAllCompletedModal({ staffMemberIds, isNegativeTotal })}
              itemRenderer={report => {
                const reportId = oFetch(report, 'id');
                const staffMemberId = oFetch(report, 'staffMemberId');
                const currentReportValidationErrors = oFetch(financeReportValidationErrors, staffMemberId);
                const staffMemberBankDetailsPermissions = oFetch(
                  staffMembersBankDetailsPermissions,
                  staffMemberId,
                );

                if (!staffMemberBankDetailsPermissions) {
                  throw new Error('Staff member bank details permissions must be present');
                }
                return (
                  <PureJSReportItem
                    onMarkCompleted={handleOpenMarkCompletedModal}
                    onUncomplete={openAreYouSureUncompleteModal}
                    weekDates={weekDates}
                    report={report}
                    isFinanceReportWeekFinished={isFinanceReportWeekFinished}
                    validationErrors={currentReportValidationErrors}
                    startDate={startDate}
                    endDate={endDate}
                    onOpenPayslip={openPayslipModal}
                    key={reportId}
                    onNoteClick={openUpdateNoteModal}
                    onViewBankDetailsClick={openBankDetailsViewModal}
                    bankDetailsPermissions={staffMemberBankDetailsPermissions}
                    paymentWeekPublished={paymentWeekPublished}
                  />
                );
              }}
            />
          );
        }}
      />
    </div>
  );
}

Page.propTypes = {
  date: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  venueId: PropTypes.number.isRequired,
  filterType: PropTypes.string.isRequired,
  staffTypesWithFinanceReports: ImmutablePropTypes.list.isRequired,
  weekDates: ImmutablePropTypes.list.isRequired,
  changePayRateFilter: PropTypes.func.isRequired,
  allReady: PropTypes.bool.isRequired,
  markReportCompleted: PropTypes.func.isRequired,
  markReportsCompleted: PropTypes.func.isRequired,
  uncompleteFinanceReport: PropTypes.func.isRequired,
};

export default Page;
