import React from 'react';
import oFetch from 'o-fetch';
import InvitesFilter from './invites-filter';
import { SimpleDashboard, DashboardFilter, DashboardActions } from '@/components/boss-dashboards';
import InviteList from './invite-list';
import { InviteRow } from './invite-row';
import InviteUser from './invite-user';
import LoadMore from '@/components/load-more/load-more-children';
import { openWarningModal } from '@/components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import { useModal } from '@/components/hooks-components/modals';
import { changeQueryString } from '../helpers';
import { resendInviteRequest } from '../requests';

function Page(props) {
  const { openModal } = useModal();
  const venues = oFetch(props, 'venues');
  const filters = oFetch(props, 'filters');
  const invites = oFetch(props, 'invites');

  function handleFilter(values) {
    const { changeRoleFilter, changeStatusFilter } = props;
    const { status, role } = values;
    changeQueryString({ status, role });
    changeRoleFilter({ role });
    changeStatusFilter({ status });
  }

  function handleInviteUserSubmit(hideModal, values) {
    return oFetch(props, 'inviteUserRequested')(values).then(hideModal);
  }

  function handleOpenInviteUserModal() {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: InviteUser,
      onSubmit: handleInviteUserSubmit,
      config: {
        title: 'Invite New User',
      },
      props: {
        venues,
      },
    });
  }

  const handleRevokeInviteSubmit = (hideModal, values) => {
    return oFetch(props, 'revokeInviteRequested')(values).then(hideModal);
  };

  const handleOpenRevokeModal = inviteId => {
    openWarningModal({
      submit: handleRevokeInviteSubmit,
      config: {
        title: 'WARNING !!!',
        text: 'Are You Sure?',
        buttonText: 'Revoke',
      },
      props: { inviteId },
    });
  };

  const handleResendInvite = args => {
    const inviteId = oFetch(args, 'inviteId');
    return resendInviteRequest({
      onSuccess: ()=>{},
      onFailure: ()=>{},
      values: { inviteId }
    });
  };

  function rowRenderer(invite) {
    return (
      <InviteRow
        invite={invite}
        onRevoke={handleOpenRevokeModal}
        onResend={handleResendInvite}
      />
    );
  }

  return (
    <div>
      <SimpleDashboard title="Invites">
        <DashboardActions>
          <button
            onClick={handleOpenInviteUserModal}
            className="boss-button boss-button_role_add boss-page-dashboard__button"
          >
            Invite New User
          </button>
        </DashboardActions>
        <DashboardFilter
          onFilter={handleFilter}
          component={InvitesFilter}
          initialValues={filters}
        />
      </SimpleDashboard>
      <LoadMore
        perPage={15}
        items={invites}
      >
        {({ visibleItems, onLoadMore }) => (
          <div className="boss-page-main__content">
            <div className="boss-page-main__inner">
              <InviteList
                invites={visibleItems}
                rowRenderer={rowRenderer}
              />
              <div className="boss-page-main__count boss-page-main__count_space_large">
                <span className="boss-page-main__count-text">Showing&nbsp;</span>
                <span className="boss-page-main__count-text boss-page-main__count-text_marked">
                  {visibleItems.length}
                </span>
                <span className="boss-page-main__count-text">&nbsp;of&nbsp;</span>
                <span className="boss-page-main__count-text boss-page-main__count-text_marked">
                  {invites.length}
                </span>
              </div>
              {visibleItems.length !== invites.length && (
                <div className="boss-page-main__actions boss-page-main__actions_position_last">
                  <button
                    onClick={onLoadMore}
                    className="boss-button boss-button_role_load-more boss-button_adjust_full-mobile"
                  >
                    Load More
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </LoadMore>
    </div>
  );
}

export default Page;
