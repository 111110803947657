import React, { useMemo } from 'react';
import { MossHourTag, MossHourTagCompositeStatus, MossHourTagInfoSectionProps, MossVenueReport, UNTAGGED_FILTER_VALUE, UntaggedMossHourTagSummary } from '../types';
import { formatAsCurrency } from '@/lib/currency-helpers';
import { minutesToFormattedUiHours } from '@/lib/hours-helpers';
import { mossFinanceReportsHourTags as importedMossFinanceReportsHourTags } from '@/lib/app-routes';
import { Collapse } from 'react-collapse';
const appRoutes = {
  mossFinanceReportsHourTags: importedMossFinanceReportsHourTags,
};
const GREY_STATUS_STYLE = { color: '#aaa' };
const READ_STATUS_STYLE = { color: '#ed7f7e' };
const GREEN_STATUS_STYLE = { color: '#78d965' };

// this signature provides static type checking ensuring switch is complete
// while the implementation below ensures failure at runtime. Comment out a switch case to see how it works
function throwBadStatus(status: never): never {
  throw new Error(`unsuppored status supplied: ${status}`);
}

export function MossHourTagInfoSection(props: MossHourTagInfoSectionProps) {
  if (props.highlightedTagId == null) {
    return null;
  }

  const [showBreakdown, setShowBreakdown] = React.useState(false);

  const mossHourTag = useMemo(() => {
    let result = null;
    if (props.highlightedTagId !== UNTAGGED_FILTER_VALUE) {
      result = props.mossHourTags.find((tag) => tag.id === props.highlightedTagId);
      if (!result) {
        throw new Error(`Could not find tag with id ${props.highlightedTagId}`);
      }
    }
    return result;
  },
  [props.highlightedTagId]);
  if (mossHourTag == null && props.highlightedTagId !== UNTAGGED_FILTER_VALUE) {
    throw new Error("mossHourTag can't be is null if not untagged");
  }

  const target: MossHourTag | UntaggedMossHourTagSummary | null = props.highlightedTagId === UNTAGGED_FILTER_VALUE ? props.untaggedMossHourTagSummary : mossHourTag;
  if (target == null) {
    throw new Error("target can't be is null");
  }

  function handleViewUntaggedDetailsClick(args: { venueId: number }) {
    return (event: React.MouseEvent) => {
      event.preventDefault();

      const url = appRoutes.mossFinanceReportsHourTags({
        startDate: props.startDate,
        venueId: args.venueId,
        mossHourTagIds: [],
        showUntagged: true,
      });

      if (event.ctrlKey === true || event.metaKey === true) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    };
  }

  function handleViewDetailsClick(args: { venueId: number, mossHourTagId: number }) {
    return (event: React.MouseEvent) => {
      event.preventDefault();

      const url = appRoutes.mossFinanceReportsHourTags({
        startDate: props.startDate,
        venueId: args.venueId,
        mossHourTagIds: [args.mossHourTagId],
        showUntagged: false,
      });

      if (event.ctrlKey === true || event.metaKey === true) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    };
  }

  function toggleBreakdown() {
    setShowBreakdown((prevShowBreakdown ) => !prevShowBreakdown);
  }

  function getStatusData(args: { status: MossHourTagCompositeStatus }): { text: string, style: React.CSSProperties } {
    switch (args.status) {
      case 'na':
        return { text: 'n/a', style: GREY_STATUS_STYLE };
      case 'pending':
        return { text: 'Pending', style: GREY_STATUS_STYLE };
      case 'requiring_update':
        return { text: 'Requiring Update', style: READ_STATUS_STYLE };
      case 'incomplete':
        return { text: 'Data Incomplete', style: READ_STATUS_STYLE };
      case 'pending_completion':
        return { text: 'Pending Completion', style: READ_STATUS_STYLE };
      case 'week_incomplete':
        return { text: 'Week Incomplete', style: READ_STATUS_STYLE };
      case 'done':
        return { text: 'Done', style: GREEN_STATUS_STYLE };
      default:
        throwBadStatus(args.status);
    }
  }

  function renderVenueReportRows(args: { target: MossHourTag | UntaggedMossHourTagSummary }): JSX.Element[] {
    return args.target.mossVenueReports.map((mossVenueReport) => renderVenueReportRow(mossVenueReport));
  }

  function renderVenueReportRow(mossVenueReport: MossVenueReport): JSX.Element {
    const statusData = getStatusData({ status: mossVenueReport.status });
    const anythingCalculating = mossVenueReport.isCalculating;
    const owedHoursCalculating = mossVenueReport.owedHoursCalculating;
    const hoursCalculating = mossVenueReport.hoursAcceptancePeriodsCalculating;

    return (
      <div className="boss-table__row">
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <div className="boss-table__label">Venue</div>
            <p className="boss-table__text">{ mossVenueReport.venueName }</p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <div className="boss-table__label">Hours</div>
            <p className="boss-table__text">
              { hoursCalculating && (
                <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                  <span className="boss-corner__icon boss-corner__icon_calculator" />
                </div>
              )}
              { minutesToFormattedUiHours({ minutes: mossVenueReport.hoursAcceptancePeriodMinutes }) }
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <div className="boss-table__label">Hours Total</div>
            <p className="boss-table__text">
              { hoursCalculating && (
                <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                  <span className="boss-corner__icon boss-corner__icon_calculator" />
                </div>
              )}
              { formatAsCurrency({ cents: mossVenueReport.hoursAcceptancePeriodCents }) }
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <div className="boss-table__label">Owed Hours</div>
            <p className="boss-table__text">
              { owedHoursCalculating && (
                <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                  <span className="boss-corner__icon boss-corner__icon_calculator" />
                </div>
              )}
              { minutesToFormattedUiHours({ minutes: mossVenueReport.owedHourMinutes }) }
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <div className="boss-table__label">Owed Hours Total</div>
            <p className="boss-table__text">
              { owedHoursCalculating && (
                <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                  <span className="boss-corner__icon boss-corner__icon_calculator" />
                </div>
              )}
              { formatAsCurrency({ cents: mossVenueReport.owedHourCents }) }
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <div className="boss-table__label">Total</div>
            <p className="boss-table__text">
              { anythingCalculating && (
                <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                  <span className="boss-corner__icon boss-corner__icon_calculator" />
                </div>
              )}
              { formatAsCurrency({ cents: mossVenueReport.owedHourCents + mossVenueReport.hoursAcceptancePeriodCents })
            }</p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <div className="boss-table__label">Status</div>
            <p
              className="boss-table__text"
              style={statusData.style}
            >
              { statusData.text }
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <div className="boss-table__actions">
              <button
                type="button"
                className="boss-button boss-button_type_ultra-small boss-button_role_view-details-light boss-table__action"
                onClick={
                  () => {
                    if (props.highlightedTagId === UNTAGGED_FILTER_VALUE) {
                      return handleViewUntaggedDetailsClick({ venueId: mossVenueReport.venueId })
                    } else {
                      if (mossHourTag == null) {
                        throw new Error('mossHourTag is null');
                      }
                      return handleViewDetailsClick({ venueId: mossVenueReport.venueId, mossHourTagId: mossHourTag.id })
                    }
                  }
                }
              >View Details</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const statusData = getStatusData({ status: target.compositeStatus });

  const anythingCalculating = target.isCalculating;
  const hoursCalculating = target.hoursAcceptancePeriodsCalculating;
  const owedHoursCalculating = target.owedHoursCalculating;

  return (
    <section className="boss-board boss-board_context_stack">
      <header className="boss-board__header">
        <h2 className="boss-board__title">
          { anythingCalculating && (
            <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
              <span className="boss-corner__icon boss-corner__icon_calculator" />
            </div>
          )}
          <span className="boss-board__title-text"><span className="boss-board__title-text-marked">{target.name}</span> { formatAsCurrency({ cents: target.totalCents }) }</span>
        </h2>
      </header>
      <div className="boss-board__main">
        <div className="boss-table boss-table_page_hours-tags-report-summary">
          <div className="boss-table__row">
            <div className="boss-table__cell boss-table__cell_role_header">Hours</div>
            <div className="boss-table__cell boss-table__cell_role_header">Hours Total</div>
            <div className="boss-table__cell boss-table__cell_role_header">Owed Hours</div>
            <div className="boss-table__cell boss-table__cell_role_header">Owed Hours Total</div>
            <div className="boss-table__cell boss-table__cell_role_header">Status</div>
            <div className="boss-table__cell boss-table__cell_role_header" />
          </div>
          <div className="boss-table__group">
            <div className="boss-table__row">
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-table__label">Hours</div>
                  <p className="boss-table__text">
                    { hoursCalculating && (
                      <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                        <span className="boss-corner__icon boss-corner__icon_calculator" />
                      </div>
                    )}
                    { minutesToFormattedUiHours({ minutes: target.hoursAcceptancePeriodMinutes }) }
                  </p>
                </div>
              </div>
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-table__label">Hours Total</div>
                  <p className="boss-table__text">
                    { hoursCalculating && (
                      <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                        <span className="boss-corner__icon boss-corner__icon_calculator" />
                      </div>
                    )}
                    { formatAsCurrency({ cents: target.hoursAcceptancePeriodCents }) }
                  </p>
                </div>
              </div>
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-table__label">Owed Hours</div>
                  <p className="boss-table__text">
                    { owedHoursCalculating && (
                      <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                        <span className="boss-corner__icon boss-corner__icon_calculator" />
                      </div>
                    )}
                    { minutesToFormattedUiHours({ minutes: target.hoursAcceptancePeriodMinutes }) }
                  </p>
                </div>
              </div>
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-table__label">Owed Hours Total</div>
                  <p className="boss-table__text">
                    { owedHoursCalculating && (
                      <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                        <span className="boss-corner__icon boss-corner__icon_calculator" />
                      </div>
                    )}
                    { formatAsCurrency({ cents: target.owedHourCents }) }
                  </p>
                </div>
              </div>
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-table__label">Status</div>
                  <p
                    className="boss-table__text"
                    style={statusData.style}
                  >{statusData.text}</p>
                </div>
              </div>
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-table__actions">
                    { !showBreakdown && (
                      <button
                        type="button"
                        className="boss-button boss-button_type_extra-small boss-button_role_show-breakdown-light"
                        onClick={toggleBreakdown}
                      >Show Breakdown</button>
                    )}
                    { showBreakdown && (
                      <button
                        type="button"
                        className="boss-button boss-button_type_extra-small boss-button_role_hide-breakdown-light"
                        onClick={toggleBreakdown}
                      >Hide Breakdown</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Collapse
              isOpened={showBreakdown}
              style={{ display: 'block' }}
            >
              <div className="boss-table__dropdown">
                <div className="boss-table boss-table_page_hours-tags-report-details">
                  <div className="boss-table__row">
                    <div className="boss-table__cell boss-table__cell_role_header">Venue</div>
                    <div className="boss-table__cell boss-table__cell_role_header">Hours</div>
                    <div className="boss-table__cell boss-table__cell_role_header">Hours Total</div>
                    <div className="boss-table__cell boss-table__cell_role_header">Owed Hours</div>
                    <div className="boss-table__cell boss-table__cell_role_header">Owed Hours Total</div>
                    <div className="boss-table__cell boss-table__cell_role_header">Total</div>
                    <div className="boss-table__cell boss-table__cell_role_header">Status</div>
                    <div className="boss-table__cell boss-table__cell_role_header" />
                  </div>
                  { renderVenueReportRows({ target }) }
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </section>
  );
}