import React from 'react';
import { WhatsappInstance, WhatsappServer } from '../types';
import { WhatsappInstanceAvailability } from './forms/whatsapp-instance-form';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import AsyncButton from 'react-async-button';
import { CheckWhatsappInstanceFromBossValues } from '../requests';

type Props = {
  instance: WhatsappInstance;
  whatsappServer: WhatsappServer,
  onEditCustomName: (whatsappServiceInstance: WhatsappInstance) => void;
  onCheckAvailability: (params: CheckWhatsappInstanceFromBossValues) => void;
  onDisable: (whatsappServiceInstance: WhatsappInstance) => void;
};

export function InstanceRow(props: Props) {
  const { instance, whatsappServer, onEditCustomName, onDisable, onCheckAvailability } = props;

  return (
    <div className="boss-table__row">
      <div
        className="boss-table__cell"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <p
            className="boss-table__text"
            style={{ marginRight: '10px' }}
          >
            <span
              className="boss-table__link"
            >
              {instance.name}
            </span>
          </p>
          <WhatsappInstanceAvailability
            isAvailable={instance.isAvailable}
          />
        </div>

        <span style={{ color: 'rgb(128, 128, 128)' }}>{instance.customName ? ` (${instance.customName})` : ''}</span>
        <span style={{ color: 'rgb(128, 128, 128)' }}>{instance.phoneNumber}</span>
      </div>
      <div className="boss-table__cell">
        <p className="boss-table__text">
          <span
            className="boss-table__link"
          >{safeMoment.iso8601Parse(instance.lastAvailabilityCheck).format(utils.humanDateFormatWithDayOfWeek())}</span>
        </p>
      </div>
      <div className="boss-table__cell">
        {instance.lastUnavailableEmailSent && (
          <p className="boss-table__text">
            <span
              className="boss-table__link"
            >{safeMoment.iso8601Parse(instance.lastUnavailableEmailSent).format(utils.humanDateFormatWithDayOfWeek())}</span>
          </p>
        )}
        {!instance.lastUnavailableEmailSent && (
          <p className="boss-table__text">
            <span
              className="boss-table__link"
            >N/A</span>
          </p>
        )}
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <div className="boss-table__actions">
            <button
              onClick={() => onEditCustomName(instance)}
              className="boss-button boss-button_type_small boss-button_role_update boss-table__action"
            >Edit Name</button>
            <AsyncButton
              className="boss-button boss-button_type_small boss-button_role_reload boss-table__action"
              text="Check Availability"
              pendingText="Checking ..."
              onClick={() => onCheckAvailability({ whatsappServerId: whatsappServer.id, whatsappInstanceId: instance.id })}
            />
            <AsyncButton
              className="boss-button boss-button_type_small boss-button_role_cancel boss-table__action"
              text="Disable"
              pendingText="Disabling ..."
              onClick={() => onDisable(instance)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
