import React from 'react';
import { MossHourTagsSectionProps, MossHourTag, UntaggedMossHourTagSummary, ALL_TAGS_ID_VALUE, UNTAGGED_FILTER_VALUE, SelectOption } from '../types';
import { formatAsCurrency } from '@/lib/currency-helpers';
import {
  mossFinanceReportsHourTags as importedMossFinanceReportsHourTags,
} from '@/lib/app-routes';
import Select from '@/lib/boss-react-select';
import Popover from 'react-popover';
import { minutesToFormattedUiHours } from '@/lib/hours-helpers';
const appRoutes = {
  mossFinanceReportHourTags: importedMossFinanceReportsHourTags,
};

type PopOverDataItem = {
  isOpen: boolean;
};

export function MossHourTagsSection(props: MossHourTagsSectionProps): JSX.Element {
  const [filterTags, setFilterTags] = React.useState<(number | typeof UNTAGGED_FILTER_VALUE)[]>([]);
  const [untaggedPopOverData, setUntaggedPopOverData] = React.useState<PopOverDataItem>({ isOpen: false });
  const [taggedPopOverData, setTaggedPopOverData] = React.useState<Record<number, PopOverDataItem>>(() => {
    const result = {} as Record<number, PopOverDataItem>;
    props.enabledTags.forEach((mossHourTag) => {
      result[mossHourTag.id] = { isOpen: false };
    });
    props.disabledTags.forEach((mossHourTag) => {
      result[mossHourTag.id] = { isOpen: false };
    });
    return result;
  });

  const filter = props.filter;
  const effectiveFilteredTagsLength = props.enabledTags.length + props.disabledTags.length + (filter.showUntagged ? 1 : 0);
  const effectiveFilteredOutTagLength = props.filteredOutMossHourTags.length + (filter.showUntagged ? 0 : 1);
  const totalMossHourTagCount = effectiveFilteredTagsLength + effectiveFilteredOutTagLength;
  const showingAll = effectiveFilteredOutTagLength === 0;

  function handleTagChange(selectedOptions: string): void {
    const arrayValues = selectedOptions
      .split(',')
      .filter(Boolean)
      .map((value) => {
        if (value === UNTAGGED_FILTER_VALUE) {
          return UNTAGGED_FILTER_VALUE;
        }
        return parseInt(value);
      });
    setFilterTags(arrayValues);
  }

  function addTags(): void {
    const filteredIds: number[] = [];
    let filteredShowUntagged: boolean = false;
    filterTags.forEach((filterTagValue) => {
      if (filterTagValue === UNTAGGED_FILTER_VALUE) {
        filteredShowUntagged = true;
      } else {
        filteredIds.push(filterTagValue);
      }
    });

    const mossHourTagIds = [
      ...props.enabledTags.map((m) => m.id),
      ...props.disabledTags.map((m) => m.id),
      ...filteredIds,
    ];

    window.location.href = appRoutes
      .mossFinanceReportHourTags({
        venueId: props.venueId,
        startDate: props.startDate,
        showUntagged: filteredShowUntagged,
        mossHourTagIds,
      });
  }

  function handleShowAllClick(): void {
    window.location.href = appRoutes.mossFinanceReportHourTags({
      venueId: props.venueId,
      startDate: props.startDate,
      mossHourTagIds: ALL_TAGS_ID_VALUE,
      showUntagged: true,
    });
  }

  function handleShowOnlyClick(mossHourTagId: number): void {
    window.location.href = appRoutes.mossFinanceReportHourTags({
      venueId: props.venueId,
      startDate: props.startDate,
      mossHourTagIds: [mossHourTagId],
      showUntagged: false,
    });
  }

  function handleShowOnlyUntaggedClick(): void {
    window.location.href = appRoutes.mossFinanceReportHourTags({
      venueId: props.venueId,
      startDate: props.startDate,
      mossHourTagIds: [],
      showUntagged: true,
    });
  }

  function handleRemoveTagClick(mossHourTagId: number): void {
    let mossHourTagIds: number[] = [];
    if (filter.mossHourTagIds === ALL_TAGS_ID_VALUE) {
      // Need all tags except this one
      props.disabledTags.forEach((mossHourTag) => {
        if (mossHourTag.id !== mossHourTagId) {
          mossHourTagIds.push(mossHourTag.id);
        }
      });
      props.enabledTags.forEach((mossHourTag) => {
        if (mossHourTag.id !== mossHourTagId) {
          mossHourTagIds.push(mossHourTag.id);
        }
      });
    } else if ((filter.mossHourTagIds.length === 1) && (filter.mossHourTagIds[0] === mossHourTagId)) {
      // need all tags so do nothing
    } else {
      // need current tags except this one
      mossHourTagIds = filter.mossHourTagIds.filter((id) => id !== mossHourTagId);
    }

    window.location.href = appRoutes.mossFinanceReportHourTags({
      venueId: props.venueId,
      startDate: props.startDate,
      showUntagged: filter.showUntagged,
      mossHourTagIds,
    });
  }

  function handleRemoveShowUntaggedClick(): void {
    window.location.href = appRoutes.mossFinanceReportHourTags({
      venueId: props.venueId,
      startDate: props.startDate,
      mossHourTagIds: filter.mossHourTagIds,
      showUntagged: false,
    });
  }

  function renderUntaggedMossHourSummary(untaggedMossHourTagSummary: UntaggedMossHourTagSummary): JSX.Element {
    const totalCents = untaggedMossHourTagSummary.owedHourCents + untaggedMossHourTagSummary.hoursAcceptancePeriodCents;
    const isOpen = untaggedPopOverData.isOpen;

    const togglePopOver = () => {
      setUntaggedPopOverData((prevUntaggedPopOverData) => {
        return {
          isOpen: !prevUntaggedPopOverData.isOpen,
        };
      });
    };

    const renderPopOverContent = (untaggedMossHourTagSummary: UntaggedMossHourTagSummary) => {
      return (
        <div
          className="boss-overview boss-overview_page_frht"
          style={{
            padding: '10px',
            backgroundColor: 'white',
          }}
        >
          <div className="boss-overview__group">
            <ul className="boss-overview__list">
              <li className="boss-overview__list-item boss-overview__list-item_role_tag-details">
                <p className="boss-overview__list-key">
                  {untaggedMossHourTagSummary.isCalculating && <span className="boss-indicator">
                    <span
                      className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_calculator"
                    />
                  </span>}
                  Hours</p>
                <p className="boss-overview__list-value">{minutesToFormattedUiHours({ minutes: untaggedMossHourTagSummary.hoursAcceptancePeriodMinutes })}</p>
              </li>
              <li className="boss-overview__list-item boss-overview__list-item_role_tag-details">
                <p
                  className="boss-overview__list-key"
                >
                  {untaggedMossHourTagSummary.isCalculating && <span className="boss-indicator">
                    <span
                      className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_calculator"
                    />
                  </span>}
                  Hours Total</p>
                <p
                  className="boss-overview__list-value"
                >{formatAsCurrency({ cents: untaggedMossHourTagSummary.hoursAcceptancePeriodCents })}</p>
              </li>
            </ul>
          </div>
          <div className="boss-overview__group boss-overview__group_position_last">
            <ul className="boss-overview__list">
              <li className="boss-overview__list-item boss-overview__list-item_role_tag-details">
                <p
                  className="boss-overview__list-key"
                >
                  {untaggedMossHourTagSummary.isCalculating && <span className="boss-indicator">
                    <span
                      className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_calculator"
                    />
                  </span>}
                  Owed Hours</p>
                <p
                  className="boss-overview__list-value"
                >{minutesToFormattedUiHours({ minutes: untaggedMossHourTagSummary.owedHourMinutes })}
                </p>
              </li>
              <li
                className="boss-overview__list-item boss-overview__list-item_role_tag-details"
              >
                <p
                  className="boss-overview__list-key"
                >
                  {untaggedMossHourTagSummary.isCalculating && <span className="boss-indicator">
                    <span
                      className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_calculator"
                    />
                  </span>}
                  Owed Hours Total</p>
                <p className="boss-overview__list-value">{formatAsCurrency({ cents: untaggedMossHourTagSummary.owedHourCents })}</p>
              </li>
            </ul>
          </div>
        </div>
      );
    };

    return (
      <Popover
        key={`untaggedMossHourTagSummary`}
        body={renderPopOverContent(untaggedMossHourTagSummary)}
        appendTarget={document.body}
        preferPlace='below'
        isOpen={isOpen}
        onOuterAction={togglePopOver}
        // style={{ marginTop: '10px' }}
        tipSize={0.01}
        className="boss-popover boss-popover_state_opened"
      >
        <div
          onClick={togglePopOver}
          key={`untaggedMossHourTagSummary`}
          className={[
            'boss-tag boss-tag_size_xl',
            'boss-tag_role_hours-tag',
            'boss-tag_role_action',
            'js-tippy-tag',
          ].filter(Boolean).join(' ')}
          aria-expanded="false"
        >
          {untaggedMossHourTagSummary.isCalculating && <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
            <span className="boss-corner__icon boss-corner__icon_calculator" />
          </div>}
          <p className="boss-tag__text">
            <i>Untagged </i>
            <span className="boss-tag__text-bold">{formatAsCurrency({ cents: totalCents })}</span>
          </p>
          <div
            className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_hide boss-tag__service_type_hidden"
            onClick={() => handleShowOnlyUntaggedClick()}
          />
          <div
            className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_close"
            onClick={() => handleRemoveShowUntaggedClick()}
          />
        </div>
      </Popover>
    );
  }

  function renderMossHourTags(args: { mossHourTags: MossHourTag[], untaggedMossHourTagSummary: UntaggedMossHourTagSummary | null }): JSX.Element[] {
    const mossHourTags = args.mossHourTags;
    const untaggedMossHourTagSummary = args.untaggedMossHourTagSummary;
    const showUntaggedMossHourTagSummary = untaggedMossHourTagSummary !== null;

    const untaggedMossHourTagHtmlAry: JSX.Element[] = showUntaggedMossHourTagSummary ? ([
      renderUntaggedMossHourSummary(untaggedMossHourTagSummary)
    ]) : [] ;

    return untaggedMossHourTagHtmlAry.concat(
      mossHourTags.map((mossHourTag) => {
        const totalCents = mossHourTag.owedHourCents + mossHourTag.hoursAcceptancePeriodCents;
        const tagPopOverData = taggedPopOverData[mossHourTag.id];
        if (!tagPopOverData) {
          throw new Error(`No popover data found for mossHourTagId: ${mossHourTag.id}`);
        }
        const isOpen = tagPopOverData.isOpen;

        const togglePopOver = () => {
          setTaggedPopOverData((prevPopOverData) => {
            const oldRecord = prevPopOverData[mossHourTag.id];
            if (!oldRecord) {
              throw new Error(`No popover data found for mossHourTagId: ${mossHourTag.id}`);
            }

            return {
              ...prevPopOverData,
              [mossHourTag.id]: {
                isOpen: !oldRecord.isOpen,
              },
            };
          });
        };

        const renderPopOverContent = (mossHourTag: MossHourTag) => {
          return (
            <div
              className="boss-overview boss-overview_page_frht"
              style={{
                padding: '10px',
                backgroundColor: 'white',
              }}
            >
              <div className="boss-overview__group">
                <ul className="boss-overview__list">
                  <li className="boss-overview__list-item boss-overview__list-item_role_tag-details">
                    <p className="boss-overview__list-key">
                      {mossHourTag.isCalculating && <span className="boss-indicator">
                        <span
                          className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_calculator"
                        />
                      </span>}
                      Hours</p>
                    <p className="boss-overview__list-value">{minutesToFormattedUiHours({ minutes: mossHourTag.hoursAcceptancePeriodMinutes })}</p>
                  </li>
                  <li className="boss-overview__list-item boss-overview__list-item_role_tag-details">
                    <p
                      className="boss-overview__list-key"
                    >
                      {mossHourTag.isCalculating && <span className="boss-indicator">
                        <span
                          className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_calculator"
                        />
                      </span>}
                      Hours Total</p>
                    <p
                      className="boss-overview__list-value"
                    >{formatAsCurrency({ cents: mossHourTag.hoursAcceptancePeriodCents })}</p>
                  </li>
                </ul>
              </div>
              <div className="boss-overview__group boss-overview__group_position_last">
                <ul className="boss-overview__list">
                  <li className="boss-overview__list-item boss-overview__list-item_role_tag-details">
                    <p
                      className="boss-overview__list-key"
                    >
                      {mossHourTag.isCalculating && <span className="boss-indicator">
                        <span
                          className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_calculator"
                        />
                      </span>}
                      Owed Hours</p>
                    <p
                      className="boss-overview__list-value"
                    >{minutesToFormattedUiHours({ minutes: mossHourTag.owedHourMinutes })}
                    </p>
                  </li>
                  <li
                    className="boss-overview__list-item boss-overview__list-item_role_tag-details"
                  >
                    <p
                      className="boss-overview__list-key"
                    >
                      {mossHourTag.isCalculating && <span className="boss-indicator">
                        <span
                          className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_calculator"
                        />
                      </span>}
                      Owed Hours Total</p>
                    <p className="boss-overview__list-value">{formatAsCurrency({ cents: mossHourTag.owedHourCents })}</p>
                  </li>
                </ul>
              </div>
            </div>
          );
        };

        return (
          <Popover
            key={`mossHourTag:${mossHourTag.id}`}
            body={renderPopOverContent(mossHourTag)}
            appendTarget={document.body}
            preferPlace='below'
            isOpen={isOpen}
            onOuterAction={togglePopOver}
            // style={{ marginTop: '10px' }}
            tipSize={0.01}
            className="boss-popover boss-popover_state_opened"
          >
            <div
              onClick={togglePopOver}
              key={`mossHourTag:${mossHourTag.id}`}
              className={[
                'boss-tag boss-tag_size_xl',
                'boss-tag_role_hours-tag',
                'boss-tag_role_action',
                'js-tippy-tag',
                mossHourTag.isDisabled ? 'boss-tag_state_disabled' : null,
              ].filter(Boolean).join(' ')}
              aria-expanded="false"
            >
              {mossHourTag.isCalculating && <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                <span className="boss-corner__icon boss-corner__icon_calculator" />
              </div>}
              <p className="boss-tag__text">
                {`${mossHourTag.name} `}
                <span className="boss-tag__text-bold">{formatAsCurrency({ cents: totalCents })}</span>
              </p>
              <div
                className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_hide boss-tag__service_type_hidden"
                onClick={() => handleShowOnlyClick(mossHourTag.id)}
              />
              <div
                className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_close"
                onClick={() => handleRemoveTagClick(mossHourTag.id)}
              />
            </div>
          </Popover>
        );
      })
    );
  }

  const selectOptions: SelectOption[] = props.filteredOutMossHourTags.map((mossHourTag) => {
    return {
      label: mossHourTag.name,
      value: mossHourTag.id
    };
  });
  if (!props.filter.showUntagged) {
    selectOptions.unshift({
      label: 'Untagged',
      value: UNTAGGED_FILTER_VALUE,
    });
  }

  return (
    <section className="boss-board boss-board_context_stack boss-board_role_hours-tags">
      <div className="boss-board__main">
        <div className="boss-board__main-inner">
          <div className="boss-board__filter">
            <div
              className="boss-form"
            >
              <div className="boss-form__row boss-form__row_align_center">
                <div className="boss-form__field boss-form__field_layout_min">
                  <p className="boss-form__text">
                    <span>Showing {effectiveFilteredTagsLength} of {totalMossHourTagCount}</span>
                    {!showingAll && (
                      <button
                        type="button"
                        className="boss-button boss-button_type_extra-small boss-form__action"
                        onClick={handleShowAllClick}
                      >Show All</button>
                    )}
                  </p>
                </div>

                <div className="boss-form__field boss-form__field_layout_max">
                  <div className="boss-form__select">
                    <Select
                      disabled={props.filteredOutMossHourTags.length === 0 && props.filter.showUntagged}
                      placeholder={"Filtered Out Tags..."}
                      value={filterTags}
                      label={null}
                      simpleValue
                      options={selectOptions}
                      multi
                      clearable={true}
                      onChange={handleTagChange}
                    />
                  </div>
                </div>
                <button
                  disabled={filterTags.length === 0 && props.filteredOutMossHourTags.length === 0}
                  onClick={() => addTags()}
                  className="boss-button"
                >Update</button>
              </div>
            </div>
          </div>

          <div className="boss-board__tags">
            {effectiveFilteredTagsLength === 0 && (
              <div className="boss-board__tags-group">
                <div className="boss-board__tags-group-inner">
                  <div className="boss-board__tags-group-text">No tags found</div>
                </div>
              </div>
            )}

            {effectiveFilteredTagsLength > 0 && (
              <div className="boss-board__tags-group">
                {(
                  renderMossHourTags({
                    mossHourTags: props.enabledTags,
                    untaggedMossHourTagSummary: filter.showUntagged ? props.untaggedMossHourTagSummary : null,
                  })
                )}
              </div>
            )}

            {props.disabledTags.length > 0 && (
              <div className="boss-board__tags-group">
                {(
                  renderMossHourTags({
                    mossHourTags: props.disabledTags,
                    untaggedMossHourTagSummary: null,
                  })
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
