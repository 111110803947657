import React from 'react';
import { MossHourTagsReportHeader } from './moss-hour-tags-report-header';
import { MossHourTagsSection } from './moss-hour-tags-section';
import { MossHourTagInfoSection } from './moss-hour-tag-info-section';
import { PageProps, HighlightedTagIdValue } from '../types';

export function Page(props: PageProps): JSX.Element {
  const [highlightedTagId, setHighlightedTagId] = React.useState<HighlightedTagIdValue>(props.mossHourTags[0]?.id || null);

  return (
    <>
      <MossHourTagsReportHeader
        filter={props.filter}
        mossHourTags={props.mossHourTags}
        startDate={props.startDate}
        endDate={props.endDate}
      />
      <MossHourTagsSection
        filter={props.filter}
        untaggedMossHourTagSummary={props.untaggedMossHourTagSummary}
        startDate={props.startDate}
        mossHourTags={props.mossHourTags}
        filteredOutMossHourTags={props.filteredOutMossHourTags}
        highlightedTagId={highlightedTagId}
        setHighlightedTagId={setHighlightedTagId}
      />
      <MossHourTagInfoSection
        startDate={props.startDate}
        highlightedTagId={highlightedTagId}
        untaggedMossHourTagSummary={props.untaggedMossHourTagSummary}
        mossHourTags={props.mossHourTags}
      />
    </>
  );
}