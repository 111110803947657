import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReportTableHeader from './report-table-header';
function ReportList(props) {
  const [isOpened, setIsOpened] = useState(true);
  const staffType = oFetch(props, 'staffType');
  const itemRenderer = oFetch(props, 'itemRenderer');
  const startDate = oFetch(props, 'startDate');
  const onMarkAllCompleted = oFetch(props, 'onMarkAllCompleted');
  const { color, name, total, allReady } = staffType.toJS();
  const roundedTotal = utils.round(total, 2);

  function toggleDropDown() {
    setIsOpened(!isOpened);
  }

  function renderItems() {
    return staffType.get('reports').map(report => {
      return React.cloneElement(itemRenderer(report), {
        key: oFetch(report, 'frontendId').toString(),
      });
    });
  }

  return (
    <section className="boss-board boss-board_context_stack boss-board_role_finance-report">
      <header className="boss-board__header">
        <h2 className="boss-board__title boss-board__title_size_medium">
          <span
            className="boss-button boss-button_type_small boss-button_type_no-behavior boss-board__title-label"
            style={{ backgroundColor: color }}
          >
            {name}
          </span>
          <span className="boss-board__title-text">
            Total: <span className="boss-board__title-text-marked">£{roundedTotal}</span>
          </span>
        </h2>
        {allReady && (
          <div className="boss-board__button-group boss-board__button-group_role_actions">
            <button
              onClick={onMarkAllCompleted}
              type="button"
              className="boss-button boss-button_type_small boss-button_role_confirm-light boss-board__action"
            >
              Mark All Complete
            </button>
          </div>
        )}
        <div className="boss-board__button-group">
          <button
            type="button"
            className={`boss-board__switch ${isOpened ? 'boss-board__switch_state_opened' : ''}`}
            onClick={toggleDropDown}
          />
        </div>
      </header>
      <Collapse
        isOpened={isOpened}
        className={`boss-board__content ${isOpened ? 'boss-board__content_state_opened' : ''}`}
        style={{ display: isOpened ? 'block' : 'none' }}
      >
        <div className="boss-board__content-inner">
          <div className="boss-board__table">
            <div className="boss-table boss-table_page_finance-reports">
              <ReportTableHeader
                staffTypeName={name}
                startDate={startDate}
              />
              <div className='boss-table__group boss-table__group_role_main'>
                <div
                  className='boss-table__group-scroll syncscroll dragscroll'
                  name={`table-${name}`}
                >
                  {renderItems()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </section>
  );
}

ReportList.propTypes = {
  staffType: ImmutablePropTypes.map.isRequired,
  startDate: PropTypes.string.isRequired,
  itemRenderer: PropTypes.func.isRequired,
  onMarkAllCompleted: PropTypes.func.isRequired,
};

export default ReportList;
