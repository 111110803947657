import { BossFormInput } from '@/components/boss-form';
import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { FaCheckCircle } from "react-icons/fa";
import { IoCloseCircleSharp } from "react-icons/io5";
import moment from 'moment';
import { checkWhatsappInstance } from '../../requests';

export type WhatsappInstanceFormValues = {
  whatsappServerId: number;
  name: string;
  customName: string | null;
  phoneNumber: string | null;
  baseUrl: string;
  instanceHash: string;
  token: string;
  lastAvailabilityCheck: string;
  isAvailable: boolean;
};

type Props = {
  onSubmit: () => void;
  initialValues: WhatsappInstanceFormValues;
  processing: boolean;
};

export function WhatsappInstanceAvailability(props: any) {
  const { isAvailable, displayText = true, text } = props;

  const deafaultText = isAvailable ? 'Available' : 'Not Available';
  const renderIcon = () => isAvailable ? <FaCheckCircle color='green' /> : <IoCloseCircleSharp color='red' />;

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
      {renderIcon()}
      {displayText && <span style={{ marginLeft: '10px' }}>{text || deafaultText}</span>}
    </div>
  );
}

export function WhatsappInstanceForm(props: Props) {
  const { onSubmit, initialValues, processing } = props;

  const [isChecked, setIsChecked] = useState(false);

  function handleTestService(values: WhatsappInstanceFormValues, form: any) {
    return checkWhatsappInstance({
      values,
      onSuccess(data: any) {
        const { instanceName, authenticated } = data;
        const now = moment().format();
        form.change('name', instanceName);
        form.change('isAvailable', authenticated);
        form.change('lastAvailabilityCheck', now);

        setIsChecked(true);
      },
      onFailure() {
        const now = moment().format();
        form.change('lastAvailabilityCheck', now);
        form.change('isAvailable', false);
        setIsChecked(true);
      }
    });
  }

  return (
    <div className="boss-modal-window__form">
      <div className="boss-form">
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, values, form, ...rest }) => {
            const disabled = submitting || processing;

            return (
              <>
                <Field
                  name={'name'}
                  label="Whatsapp Service Instance Name"
                  parse={value => value}
                  disabled={processing}
                  description={`This field will be filled after instance check`}
                  component={BossFormInput}
                />
                <Field
                  name={'phoneNumber'}
                  label="Whatsapp Service Phone Number"
                  disabled
                  parse={value => value}
                  description={`This field will be filled after instance check`}
                  component={BossFormInput}
                />
                <Field
                  name={'customName'}
                  label="Whatsapp Service Instance Custom Name"
                  parse={value => value}
                  disabled={processing}
                  component={BossFormInput}
                />
                <Field
                  name={'instanceHash'}
                  label="Instance Hash"
                  parse={value => value}
                  disabled={processing}
                  component={BossFormInput}
                />
                <Field
                  name={'token'}
                  label="Token"
                  parse={value => value}
                  disabled={processing}
                  component={BossFormInput}
                />
                <div className="boss-form__field">
                  <div
                    className='boss-page-dashboard__buttons-group boss-page-dashboard_page_hours-confirmation'
                    style={{ alignItems: 'center' }}
                  >
                    {!isChecked && (
                      <button
                        onClick={() => handleTestService(values as WhatsappInstanceFormValues, form)}
                        disabled={disabled}
                        className="boss-button boss-button_role_confirm boss-form__submit boss-page-dashboard__button"
                        type="button"
                      >
                        Check
                      </button>
                    )}
                    {isChecked && <WhatsappInstanceAvailability isAvailable={values['isAvailable'] as boolean} />}
                    <button
                      onClick={handleSubmit}
                      disabled={!isChecked}
                      className="boss-button boss-button_role_confirm boss-form__submit boss-page-dashboard__button"
                      type="button"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
}
