import React from 'react';
import PropTypes from 'prop-types';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';

function ReportTableHeader(props) {
  const staffTypeName = oFetch(props, 'staffTypeName');

  function renderWeekCells() {
    const startDate = safeMoment.uiDateParse(oFetch(props, 'startDate'));

    return [1, 2, 3, 4, 5, 6, 7].map(weekDay => {
      const currentDate = startDate.isoWeekday(weekDay);
      return (
        <div
          key={`${staffTypeName}:header:${weekDay}`}
          className="boss-table__cell boss-table__cell_role_header"
        >
          {currentDate.format(utils.tableDateFormat)} <br /> {currentDate.format(utils.monthDateFormat)}
        </div>
      );
    });
  }

  return (
    <div className='boss-table__group boss-table__group_role_header'>
      <div
        className='boss-table__group-scroll syncscroll dragscroll'
        name={`table-${staffTypeName}`}
      >
        <div className="boss-table__row">
          <div className="boss-table__cell boss-table__cell_role_header">Name</div>
          <div className="boss-table__cell boss-table__cell_role_header">Sage ID</div>
          {renderWeekCells()}
          <div className="boss-table__cell boss-table__cell_role_header">Weekly Hours</div>
          <div className="boss-table__cell boss-table__cell_role_header">Owed Hours</div>
          <div className="boss-table__cell boss-table__cell_role_header">Accessories</div>
          <div className="boss-table__cell boss-table__cell_role_header">Pay rate</div>
          <div className="boss-table__cell boss-table__cell_role_header">Total Hours</div>
          <div className="boss-table__cell boss-table__cell_role_header">Paid Holidays (Days)</div>
          <div className="boss-table__cell boss-table__cell_role_header">Sick Leave (Days)</div>
          <div className="boss-table__cell boss-table__cell_role_header">Total</div>
          <div className="boss-table__cell boss-table__cell_role_header">Net Wages</div>
          <div className="boss-table__cell boss-table__cell_role_header">Status</div>
          <div className="boss-table__cell boss-table__cell_role_header">BD</div>
          <div className="boss-table__cell boss-table__cell_role_header">Notes</div>
        </div>
      </div>
    </div>
  );
}

ReportTableHeader.propTypes = {
  startDate: PropTypes.string.isRequired,
};

export default ReportTableHeader;
