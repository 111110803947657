/* eslint-disable react/prop-types */
import BossWeekPicker from "@/components/react-dates/boss-week-picker";
import React from "react";
import Popover from "react-popover";
import { format } from "date-fns";
import { mossHourTagsReportIndex as importedMossHourTagsReportIndex } from "@/lib/app-routes";
const appRoutes = {
  mossHourTagsReportIndex: importedMossHourTagsReportIndex,
};
import { COMMON_DATE_FORMAT, UI_DATE_FORMAT } from "@/lib/date-fns-formats";
import z from "zod";
import { $BossUiDate } from "@/lib/schema-funcs";
import { MossHourTagsReportHeaderProps } from "../types";

export function MossHourTagsReportHeader(props: MossHourTagsReportHeaderProps) {
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const filter = props.filter;
  const togglePopover = () => setIsCalendarOpen(!isCalendarOpen);

  function onDateChange(date: Date): void {
    window.location.href = appRoutes
      .mossHourTagsReportIndex({
        startDate: date,
        mossHourTagIds: filter.mossHourTagIds,
        showUntagged: filter.showUntagged,
      });
  }

  function renderCalendar(): JSX.Element {
    return (
      <div className="boss-popover__inner">
        <BossWeekPicker
          selectionStartUIDate={format(props.startDate, UI_DATE_FORMAT)}
          onChange={(selection: unknown) => {
            onDateChange(
              z.object({ startUIDate: $BossUiDate(z) }).parse(selection).startUIDate
            );
          }}
          onCancelClick={() => setIsCalendarOpen(false)}
        />
      </div>
    );
  }


  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_hours-tags-report">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">Hours Tags Report</h1>

            <div className="boss-page-dashboard__meta">
              <Popover
                isOpen={isCalendarOpen}
                body={renderCalendar()}
                appendTarget={document.body}
                place="below"
                tipSize={0.01}
                onOuterAction={togglePopover}
                className="boss-popover boss-popover_context_dashboard-week-picker boss-popover_state_opened"
                style={{ marginTop: '10px' }}
              >
                <p
                  className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_type_faded boss-page-dashboard__meta-item_role_date boss-page-dashboard__meta-item_role_popover"
                  onClick={togglePopover}
                >
                  <span className="boss-page-dashboard__meta-text">
                    {format(props.startDate, COMMON_DATE_FORMAT)}
                  </span>
                  {' - '}
                  <span className="boss-page-dashboard__meta-text">
                    {format(props.endDate, COMMON_DATE_FORMAT)}
                  </span>
                </p>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}