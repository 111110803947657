import React from 'react';
import { Dashboard, GroupItem, InstanceRow, InstancesTable } from './components';
import ContentWrapper from '@/components/content-wrapper';
import { useConfirmationModal, useModal } from '@/components/hooks-components/modals';
import { WhatsappServiceForm, WhatsappServerFormValues } from './components/forms/whatsapp-server-form';
import { useWhatsappServers } from './use-whatsapp-servers';
import { WhatsappServer, WhatsappInstance } from './types';
import { WhatsappInstanceForm, WhatsappInstanceFormValues } from './components/forms/whatsapp-instance-form';
import { WhatsappInstanceCustomNameForm } from './components/forms/whatsapp-instance-custom-name-form';

type Props = {};

export function WhatsappServers(props: Props) {
  const {
    whatsappServers,
    createWhatsappServer,
    createWhatsappInstance,
    updateWhatsappInstanceCustomName,
    restartWhatsappServer,
    disableWhatsappInstance,
    checkAvailability
  } = useWhatsappServers(props);

  const { openProcessingModal } = useModal();
  const { openWarningModal } = useConfirmationModal();

  function openNewWhatsappServiceModal() {
    return openProcessingModal({
      onSubmit(handleClose: any, setProcessing: any, values: WhatsappServerFormValues) {
        setProcessing(true);
        return createWhatsappServer({
          values,
          onSuccess() {
            setProcessing(false);
            handleClose();
          },
          onFailure() {
            setProcessing(false);
          }
        });
      },
      config: {
        title: 'New Whatsapp Server'
      },
      props: {
        initialValues: {
          name: '',
          baseUrl: '',
          ssh: '',
        }
      },
      ModalContent: WhatsappServiceForm
    });
  }

  function openNewWhatsappServiceInstanceModal(whatsappServer: WhatsappServer) {
    return openProcessingModal({
      onSubmit(handleClose: any, setProcessing: any, values: WhatsappInstanceFormValues) {
        setProcessing(true);
        return createWhatsappInstance({
          values,
          onSuccess() {
            setProcessing(false);
            handleClose();
          },
          onFailure() {
            setProcessing(false);
          }
        });
      },
      config: {
        title: 'New Whatsapp Instance'
      },
      props: {
        initialValues: {
          whatsappServerId: whatsappServer.id,
          name: '',
          customName: '',
          baseUrl: whatsappServer.baseUrl,
          phoneNumber: '',
          instanceHash: '',
          token: '',
          lastAvailabilityCheck: '',
          isAvailable: false,
        }
      },
      ModalContent: WhatsappInstanceForm
    });
  }

  function openInstanceUpdateCustomName(whatsappInstance: WhatsappInstance) {
    const { id: whatsappInstanceId, whatsappServerId } = whatsappInstance;
    return openProcessingModal({
      onSubmit(handleClose: any, setProcessing: any, values: any) {
        setProcessing(true);
        return updateWhatsappInstanceCustomName({
          values,
          onSuccess() {
            setProcessing(false);
            handleClose();
          },
          onFailure() {
            setProcessing(false);
          }
        });
      },
      config: {
        title: 'Update Whatsapp Instance custom name'
      },
      props: {
        initialValues: {
          whatsappInstanceId,
          whatsappServerId,
          customName: whatsappInstance.customName,
        }
      },
      ModalContent: WhatsappInstanceCustomNameForm
    });
  }

  function openRestartServiceConfirmation(whatsappServerId: number) {
    return openWarningModal({
      onSubmit(closeModal: any) {
        closeModal();
        return restartWhatsappServer({
          whatsappServerId,
          onSuccess() { },
          onFailure() { }
        });
      },
      props: {
        text: 'Are you sure ?',
        buttonText: 'Restart',
      },
      config: {
        title: 'RESTART WHATSAPP SERVER',
      },
    });
  }

  function openDisableInstanceConfirmation(whatsappInstance: WhatsappInstance) {
    return openWarningModal({
      onSubmit(closeModal: any) {
        closeModal();

        return disableWhatsappInstance({
          whatsappInstance,
          onSuccess() { },
          onFailure() { }
        });
      },
      props: {
        text: 'Are you sure ?',
        buttonText: 'Disable',
      },
      config: {
        title: 'DISABLE WHATSAPP INSTANCE',
      },
    });
  }

  return (
    <>
      <Dashboard onAddNew={openNewWhatsappServiceModal} />
      <ContentWrapper>
        {whatsappServers.map((whatsappServer: WhatsappServer) => {
          const { id, instances } = whatsappServer;
          return (
            <GroupItem
              key={id}
              whatsappServer={whatsappServer}
              onAddNewInstance={openNewWhatsappServiceInstanceModal}
              onRestart={openRestartServiceConfirmation}
            >
              <InstancesTable
                instances={instances}
                renderItem={(instance: WhatsappInstance) => (
                  <InstanceRow
                    instance={instance}
                    whatsappServer={whatsappServer}
                    onCheckAvailability={checkAvailability}
                    onEditCustomName={openInstanceUpdateCustomName}
                    onDisable={openDisableInstanceConfirmation}
                  />
                )}
              />
            </GroupItem>
          );
        })}
      </ContentWrapper>
    </>
  );
}
