import oFetch from 'o-fetch';
import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import Select from '@/lib/boss-react-select';
import { AppAccessItemField } from './app-access-item-field';

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

export function AppAccessField(props) {
  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedApps, setSelectedApps] = useState([]);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [apps, venues] = oFetch(useLegacyGlobal(), 'apps', 'venues');
  const fields = oFetch(props, 'fields');
  const form = oFetch(props, 'form');
  const value = oFetch(fields, 'value');
  const { onAppSelect, onVenueSelect } = props;

  const allAppsVenues = useMemo(() => {
    return value.reduce((acc, appItem) => {
      const venueIds = oFetch(appItem, 'venueIds');
      return [...acc, ...venueIds];
    }, []).filter(onlyUnique);
  }, [value]);

  const appsOptions = useMemo(() => {
    return apps
      .filter(app => {
        const selectedAppsIds = selectedApps.map(selectedApp => oFetch(selectedApp, 'id'));
        return !selectedAppsIds.includes(oFetch(app, 'id'));
      })
      .map(app => {
        return {
          value: oFetch(app, 'id'),
          label: oFetch(app, 'title'),
        };
      });
  }, [apps, selectedApps]);

  const allVenuesOptions = useMemo(() => {
    return venues.reduce((acc, venue) => {
      if (oFetch(venue, 'disabled')) {
        return acc;
      }
      const [isMoss, isBoss] = oFetch(venue, 'isMoss', 'isBoss');

      return [
        ...acc,
        {
          value: oFetch(venue, 'id'),
          label: oFetch(venue, 'name'),
          isMoss,
          isBoss,
        }
      ];
    }, []);
  }, []);

  useEffect(() => {
    if (selectedApp) {
      const app = apps.find(app => oFetch(app, 'id') === selectedApp);
      if (!app) {
        throw new Error('Wrong app id');
      }
      const [appIsMoss, appIsBoss] = oFetch(app, 'isMoss', 'isBoss');

      const initialVenuesForSelectedApp = venues.reduce((acc, venue) => {
        if (oFetch(venue, 'disabled')) {
          return acc;
        }
        const [isMoss, isBoss] = oFetch(venue, 'isMoss', 'isBoss');
        const venueId = oFetch(venue, 'id');
        if (!allAppsVenues.includes(venueId)) {
          return acc;
        }
        return [
          ...acc,
          {
            value: venueId,
            label: oFetch(venue, 'name'),
            isMoss,
            isBoss,
          }
        ];
      }, []).reduce((acc, venueOption) => {
        let [isMoss, isBoss] = oFetch(venueOption, 'isMoss', 'isBoss');
        let includeVenue = (appIsMoss && isMoss) || (appIsBoss && isBoss);

        if (includeVenue) {
          acc = [...acc, oFetch(venueOption, 'value')];
        }

        return acc;
      }, []);
      setSelectedVenues(initialVenuesForSelectedApp);
    }
  }, [selectedApp, allAppsVenues]);

  const venuesOptions = useMemo(() => {
    if (!selectedApp) return [];
    const app = apps.find(app => oFetch(app, 'id') === selectedApp);
    if (!app) {
      throw new Error('Wrong app id');
    }
    const [appIsMoss, appIsBoss] = oFetch(app, 'isMoss', 'isBoss');

    return allVenuesOptions.reduce((acc, venueOption) => {
      let [isMoss, isBoss] = oFetch(venueOption, 'isMoss', 'isBoss');
      let includeVenue = (appIsMoss && isMoss) || (appIsBoss && isBoss);

      if (includeVenue) {
        acc = [...acc, venueOption];
      }

      return acc;
    }, []);
  }, [selectedApp]);

  const isAllSelected = venuesOptions.length === selectedVenues.length;
  const anySelected = selectedVenues.length > 0;

  function handleAddApp(selectedApp, selectedVenues) {
    fields.push({ appId: selectedApp, venueIds: selectedVenues });
    const app = apps.find(app => oFetch(app, 'id') === selectedApp);
    if (!app) {
      throw new Error('Wron app id');
    }
    setSelectedApps([...selectedApps, app]);
    if (onAppSelect) {
      onAppSelect(null);
    }
    if (onVenueSelect) {
      onVenueSelect([]);
    }
    setSelectedApp(null);
  }

  function handleAppRemove(index) {
    setSelectedApps(selectedApps => {
      return selectedApps.filter((item, itemIndex) => {
        return itemIndex !== index;
      });
    });
    onAppSelect && onAppSelect(null);
    onVenueSelect && onVenueSelect([]);
    if (selectedApps.length === 1) {
      form.change(fields.name, []);
    } else {
      fields.remove(index);
    }
  }

  function handleAppSelect(app) {
    setSelectedApp(app);
    onAppSelect &&
      onAppSelect(
        app === null
          ? app
          : oFetch(
            apps.find(appObj => oFetch(appObj, 'id') === app),
            'title',
          ),
      );
    setSelectedVenues([]);
  }

  function handleVenuesChange(selected) {
    const arrayValues = selected
      .split(',')
      .filter(Boolean)
      .map(id => parseInt(id));
    onVenueSelect && onVenueSelect(arrayValues);
    setSelectedVenues(arrayValues);
  }

  function handleClearAll() {
    onVenueSelect && onVenueSelect([]);
    setSelectedVenues([]);
  }

  function handleSelectAll() {
    const allValues = venuesOptions.map(option => oFetch(option, 'value'));
    onVenueSelect && onVenueSelect(allValues);
    setSelectedVenues(allValues);
  }

  if (selectedApps.length !== fields.length) {
    return null;
  }

  return (
    <div className="boss-form__group">
      <p className="boss-form__label">
        <span className="boss-form__label-text">App Permanent Access</span>
      </p>
      <div className="boss-form__group boss-form__group_role_board">
        <div className="boss-form__group boss-form__group_role_area">
          <div className="boss-form__row boss-form__row_layout_nowrap boss-form__row">
            <div className="boss-form__field">
              <label className="boss-form__label">
                <span className="boss-form__label-text">App Name</span>
              </label>
              <div className="boss-form__select">
                <Select
                  value={selectedApp}
                  onChange={handleAppSelect}
                  options={appsOptions}
                  simpleValue
                  clearable
                  multi={false}
                />
              </div>
            </div>
          </div>
          <div className="boss-form__row boss-form__row_layout_nowrap boss-form__row">
            <div className={'boss-form__field'}>
              <div className="boss-form__label-group">
                <label className="boss-form__label">
                  <span className="boss-form__label-text">Venues</span>
                </label>
                <div className="boss-form__label-actions">
                  {anySelected && (
                    <button
                      onClick={handleClearAll}
                      type="button"
                      className="boss-action boss-action_role_form boss-form__label-action"
                    >
                      <span className="boss-action__text">Clear All</span>
                    </button>
                  )}
                  {!isAllSelected && (
                    <button
                      onClick={handleSelectAll}
                      type="button"
                      className="boss-action boss-action_role_form boss-form__label-action"
                    >
                      <span className="boss-action__text">Add All</span>
                    </button>
                  )}
                </div>
              </div>
              <div className={'boss-form__select'}>
                <Select
                  value={selectedVenues}
                  onChange={handleVenuesChange}
                  clearable
                  simpleValue
                  multi
                  placeholder={'Select venues'}
                  options={venuesOptions}
                />
              </div>
            </div>
          </div>
          <div className="boss-form__row boss-form__row_layout_nowrap boss-form__row">
            <div className="boss-form__field boss-form__field_layout_min">
              <button
                type="button"
                onClick={() => handleAddApp(selectedApp, selectedVenues)}
                disabled={!selectedApp || !anySelected}
                className="boss-button boss-button_role_add boss-form__submit"
              >
                Add App Permissions
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="boss-form__group boss-form__group_role_area">
        {fields.map((fieldName, index) => {
          return (
            <Field
              key={fieldName}
              app={selectedApps[index]}
              onRemove={() => handleAppRemove(index)}
              options={allVenuesOptions}
              label={'Permanent Access'}
              placeholder={'Add venues'}
              component={AppAccessItemField}
              name={`${fieldName}.venueIds`}
            />
          );
        })}
      </div>
    </div>
  );
}
